import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app.settings';
import { MasterDataService } from 'src/app/xp-admin-web/admin/master-data/master-data.service';
import { AlertService } from '../services/alert.service';
import { LclEwaybillPortalService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-ewaybill-portal/lcl-ewaybill-portal.service';
import { TextValidator } from 'src/app/_helpers/text-validator';

@Component({
  selector: 'app-update-part-b-dialog',
  templateUrl: './update-part-b-dialog.component.html',
  styleUrls: ['./update-part-b-dialog.component.scss']
})
export class UpdatePartBDialogComponent implements OnInit {
  isEdit = false;
  isSubmitting = false;
  nameExists = false;
  codeExists = false;
  allList = [];
  filteredList = [];
  addFrom: FormGroup;
  stateList: any[] = [];
  constructor(public dialogRef: MatDialogRef<UpdatePartBDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public alertService: AlertService,
    private _ewbPortalService: LclEwaybillPortalService,
    private _upsertdistrict: MasterDataService,
    private _appsetting: AppSettings,
    private _formbuilder: FormBuilder
  ) {
    this.addFrom = this._formbuilder.group({
      'eway_bill_number': [null, Validators.compose([Validators.required, Validators.maxLength(12)])],
      'place_of_consignor': [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      'state_of_consignor': [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      'vehicle_number': [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      'transporter_document_number': [null],
      'transporter_document_date': [null],
    });
  }

  close(): void {
    this.dialogRef.close()
  }

  ngOnInit() {
    
  }
  
  onlyNumbers(event) {
    return TextValidator.onlyNumbers(event);
  }
  
  onSubmit(value: Object) {
    if (this.addFrom.valid) {
      if (!this.nameExists && !this.codeExists) {
        this._ewbPortalService.InsertUpdatePartBLog(value).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Success']);
              }
              else {
                this.isSubmitting = false;
              }
            }
          }
        })
      }
    }
    else {
      this.addFrom.controls['state_of_consignor'].markAsTouched();
      this.addFrom.controls['eway_bill_number'].markAsTouched();
      this.addFrom.controls['vehicle_number'].markAsTouched();
      this.addFrom.controls['place_of_consignor'].markAsTouched();
      // this.addFrom.controls['transporter_document_number'].markAsTouched();
      // this.addFrom.controls['transporter_document_date'].markAsTouched();
    }
  }

}
