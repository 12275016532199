import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TripService {

  constructor(private _http: HttpClient) { }

  // to get the list of customer options
  getTripsFlow(data) {
    return this._http.post(environment.apiUrl + 'api/services/trip/get-services-trips-flow',data);
  }

  getAlertsFlow(data) {
    return this._http.post(environment.apiUrl + 'api/alerts-trip-flow',data);
  }


  getOrdersPlacementTracking(data) {
    return this._http.post(environment.apiUrl + 'api/services/trip/get-orders-placemet-tracking',data);
  }
  getOrdersHaltedTrips(data) {
    return this._http.post(environment.apiUrl + 'api/services/trip/get-services-trips-halted',data);
  }
  getTripsFlowArrived(data) {
    return this._http.post(environment.apiUrl + 'api/services/trip/get-services-arrived',data);
  }
  getTripsFlowWaitingLoad(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-waiting-for-loading',data);
  }
  UpdateTripDelayDetails(data) {
    return this._http.post(environment.apiUrl + 'api/services/trip/update-trip-manual-location-details', data);
  }
  UpdateTripDelayDetailsLoading(data) {
    return this._http.post(environment.apiUrl + 'api/update-delay-reason-loading-orders', data);
  }
  UpdateAlertRemark(data) {
    return this._http.post(environment.apiUrl + 'api/update-alert-remark', data);
  }
  GetTripsDelayDropdowns() {
    return this._http.get(environment.apiUrl + 'api/services/trip/get-trips-dropdowns');
  }
  GetLocationOnClick() {
    return this._http.get(environment.apiUrl + 'api/get-vehicle-location-manual-update');
  }

  GetTripsCases(tripId) {
    return this._http.get(environment.apiUrl + 'api/services/trip/get-trips-cases/' + tripId);
  }
  GetTripsCasesLog(tripId) {
    return this._http.get(environment.apiUrl + 'api/services/trip/get-trips-cases-log/' + tripId);
  }
  GetTripsCasesCLosed(tripId) {
    return this._http.get(environment.apiUrl + 'api/services/trip/get-trips-cases-closed/' + tripId);
  }

  GetTripsCasesCLosedPie() {
    return this._http.get(environment.apiUrl + 'api/services/trip/get-trips-openclose');
  }

  DeleteTripCase(caseId) {
    return this._http.get(environment.apiUrl + 'api/services/trip/delete-trips-cases/' + caseId);
  }
  CloseTripCase(caseId,reason:string) {
    return this._http.get(environment.apiUrl + 'api/close-trip-case?caseId=' + caseId+'&&reason=' + reason);
  }
  TripCasesDropDown() {
    return this._http.get(environment.apiUrl + 'api/services/trip/trip-case-dropdowns');
  }
  AlertDropDown() {
    return this._http.get(environment.apiUrl + 'api/services/alert/alert-dropdowns');
  }
  UpsertTripCase(data) {
    return this._http.post(environment.apiUrl + 'api/services/trip/upsert-trips-case', data);
  }
  GetEntitiesForPerformance(filter) {
    return this._http.post(environment.apiUrl + 'api/services/get-entities-performance' ,filter);
  }
  GetEntitiesForPerformanceTrips(filter) {
    return this._http.post(environment.apiUrl + 'api/services/get-entities-performance-trip',filter);
  }
  GetZoneTrips(data){
    return this._http.post(environment.apiUrl + 'api/services/get-zone-trips',data);
  }
  getTripsForServices(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-trips-service-level',data);
  }
  GetGraph(filter) {
    return this._http.post(environment.apiUrl + 'api/services/get-graph-performance',filter);
  }
  GetTripsGraph() {
    return this._http.get(environment.apiUrl + 'api/get-graph-trips');
  }
  GetIntransitGraphCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/get-graph-cutomer-intranist',data);
  }
  GetPlacementGraphCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/get-graph-cutomer-placement',data);
  }
  GetAlertGraphCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/get-graph-customer-for-alerts',data);
  }
  GetHaltedGraphCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/get-graph-customer-for-halted',data);
  }
  GetIntransitGraphFFV(data) {
    return this._http.post(environment.apiUrl + 'api/get-graph-ffv-intranist',data);
  }
  GePlacementGraphFFV(data) {
    return this._http.post(environment.apiUrl + 'api/get-graph-ffv-placement',data);
  }
  GetAlertGraphFFV(data) {
    return this._http.post(environment.apiUrl + 'api/get-graph-ffv-for-alert',data);
  }
  GetAlertTabs() {
    return this._http.get(environment.apiUrl + 'api/get-tab-numbers-for-alerts');
  }
  GeHaltedGraphFFV(data) {
    return this._http.post(environment.apiUrl + 'api/get-graph-ffv-for-halted',data);
  }
  GetIntransitGraphDelay(data) {
    return this._http.post(environment.apiUrl + 'api/get-graph-delay-intranist',data);
  }
  GetPlacementGraphDelay(data) {
    return this._http.post(environment.apiUrl + 'api/get-graph-delay-placement',data);
  }
  GetHaltingHaltedHoursGraphDelay(data) {
    return this._http.post(environment.apiUrl + 'api/get-graph-waiting-hours-for-halted',data);
  }
  GetIntransitGraphDelayCount(data) {
    return this._http.post(environment.apiUrl + 'api/get-count-for-delay',data);
  }
  GetIntransitGraphETACount(data) {
    return this._http.post(environment.apiUrl + 'api/get-count-for-eta',data);
  }
  GetPlacementETPCount(data) {
    return this._http.post(environment.apiUrl + 'api/get-count-for-etp',data);
  }
  GetArrivedGraphCustomer() {
    return this._http.get(environment.apiUrl + 'api/get-graph-cutomer-arrived');
  }
  GetCustomerOrderByDistanceNumbers() {
    return this._http.get(environment.apiUrl + 'api/get-numbers-for-order-by-distance');
  }
  GetOrderByDistanceHaltedNumbers() {
    return this._http.get(environment.apiUrl + 'api/get-orders-for-halted-by-distance');
  }
  GetPlacementOrderByDistanceNumbers() {
    return this._http.get(environment.apiUrl + 'api/get-orders-for-placement-by-distance');
  }
  GetPerformanceOrderByDistanceNumbers(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-performance-by-distance',data);
  }
  GetArrivedGraphFFV() {
    return this._http.get(environment.apiUrl + 'api/get-graph-ffv-arrived');
  }
  GetArrivedGraph() {
    return this._http.get(environment.apiUrl + 'api/get-graph-ffv-arrived');
  }
  GetArrivedGraphUnloading(data) {
    return this._http.post(environment.apiUrl + 'api/get-graph-halting-hours',data);
  }
  GetWaitingForLoadnigGraph(data) {
    return this._http.post(environment.apiUrl + 'api/get-graph-waiting-hours-for-loading',data);
  }
  GetFFVForLoadnigGraph(data) {
    return this._http.post(environment.apiUrl + 'api/get-graph-ffv-for-loading',data);
  }
  GetCustomerForLoadnigGraph(data) {
    return this._http.post(environment.apiUrl + 'api/get-graph-customer-for-loading',data);
  }
  GetCountTabs() {
    return this._http.get(environment.apiUrl + 'api/get-count-above-tabs');
  }
  sendEmailsWithCSV(data) {
    return this._http.post(environment.apiUrl + 'api/send-mis-emails-csv', data);
  }
  UpdateValidateLoading(data) {
    return this._http.post(environment.apiUrl + 'api/update-validate-loading', data);
  }
    //to validate unloading log
    validateUnloadingLog(data) {
      return this._http.post(environment.apiUrl + 'api/validate-unloading-log', data);
    }

    GetTripHaltedDetails(OrderId) {
      return this._http.get(environment.apiUrl + 'api/services/get-halted-trip-details?OrderId='+OrderId);
    }
}
