import { Component, Inject, OnInit, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../services/alert.service';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';
import { DownloadExcelService } from 'src/app/services/download-excel.service';

@Component({
  selector: 'app-upload-multiple-csv',
  templateUrl: './upload-multiple-csv.component.html',
  styleUrls: ['./upload-multiple-csv.component.scss']
})
export class UploadMultipleCsvComponent implements OnInit {

  public formData: FormData = new FormData();
  public uploadMultipleFilesDialogData: UploadMultipleFilesDialogData;
  public selectedFiles: File[] = [];
  public fileUploadStatus : boolean = false;
  public responseReceivedFromServer : boolean = false;
  public fileUploadErrorMessage : string = "";
  public successfulUploads : number = 0
  public failedUploads : any[] = []
  public userDownloadedFailedUploads : boolean = true
  public isUploadRequested : boolean = false



  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadMultipleFilesDialogData>,
    private alertService: AlertService, public lclTranshipmentService: LclTranshipmentService,private _downloadExcelService: DownloadExcelService) {
      this.dialogRef.disableClose = true
    this.uploadMultipleFilesDialogData = this.data.uploadMultipleFilesDialogData;
    if (this.isNotNullUndefinedAndEmpty(this.data.uploadMultipleFilesDialogData.apiBody)) {
      this.formData.append('FormData', JSON.stringify(this.uploadMultipleFilesDialogData.apiBody));
      this.formData.append('ActionType', this.uploadMultipleFilesDialogData.actionType)
    }
  }

  ngOnInit(): void { }


  handleFileInput(files: FileList, fileDetails: IndividualFileDetails, index: number) {
    if (files != null && files.length > 0) {
      const acceptedFileTypes = [fileDetails.fileType];
      let filename = files.item(0).name;
      let fileType = files.item(0).type
      if (acceptedFileTypes.includes(fileType)) {
        if (files.item(0).size <= 10000000) {
          if ((fileDetails.shouldCheckForValidFileName && fileDetails.fileName.split(".")[0] == filename.split(".")[0]) || !fileDetails.shouldCheckForValidFileName) {
            this.uploadMultipleFilesDialogData.individualFileDetails[index].file = files.item(0);
          }
          else {
            this.uploadMultipleFilesDialogData.individualFileDetails[index].file = null;
            this.alertService.createAlert(`Please select ${fileDetails.fileName}`, 0)
          }

        } else {
          this.alertService.createAlert('File size must be less than 10 MB', 0);
          this.uploadMultipleFilesDialogData.individualFileDetails[index].file = null;
        }
      }
      else {
        this.alertService.createAlert('Wrong File Format', 0);
        this.uploadMultipleFilesDialogData.individualFileDetails[index].file = null;
        return;
      }
    }
  }

  isNotNullUndefinedAndEmpty(value: any): boolean {
    return value != null && value !== undefined && value !== '';
  }

  onConfirmation() {
    this.isUploadRequested = true
    if (this.areAllFilesSelected()) {
      this.responseReceivedFromServer = false
      this.uploadMultipleFilesDialogData.individualFileDetails.forEach(x => {
        let fileName: string = x.fileName.split(".")[0]
        this.formData.delete(fileName)
        this.formData.append(fileName, x.file)
      })
      this.lclTranshipmentService.readAndProcessExcelFiles(this.formData).subscribe(res => {
        
         if(res !=null){
          this.responseReceivedFromServer = true;
          this.isUploadRequested = false
            const data = res["Data"]
            this.fileUploadStatus = data['Success']
            this.fileUploadErrorMessage = data['ErrorMessage']
            this.successfulUploads = data['NoOfSuccessfullUploads']
            this.failedUploads = data['FailedRecords']
            if(this.failedUploads.length > 1) this.userDownloadedFailedUploads = false
         }
      })
    }
    else{
      this.isUploadRequested = false
    }
  }

  areAllFilesSelected(): boolean {
    for (let x of this.uploadMultipleFilesDialogData.individualFileDetails) {
      if (!this.isNotNullUndefinedAndEmpty(x.file)) {
        this.alertService.createAlert(`Please select ${x.fileName}`, 2);
        return false;
      }
    }
    return true;
  }

  close(){
    if(this.userDownloadedFailedUploads)  this.dialogRef.close()
    else this.alertService.createAlert("Please download Failed Uploads Data!",2)
  }

  downloadFailedUploadDetails() {
    let excelData: any[] = [];
    
    // Get the dynamic key for "Identifier"
    const identifierHeader = this.failedUploads[0]["Identifier"];
    
    if (this.failedUploads.length > 1) {
      this.failedUploads.forEach((element, index) => {
        if (index > 0) {
          // Use bracket notation for the dynamic key
          excelData.push({
            "S No":index+1,
            [identifierHeader]: element["Identifier"],
            "Failed Reason": element["FailedReason"],
            "File Name": element["FileName"]
          });
        }
      });
    } else {
      excelData.push({
        "S No":"",
        [identifierHeader]: "",
        "FailedReason": "",
        "File Name": ""
      });
    }
  
    // Export the excel data
    this._downloadExcelService.exportAsExcelFile(excelData, 'Failed Uploads Details');
    this.userDownloadedFailedUploads = true
  }
  

}


export class UploadMultipleFilesDialogData {
  public dialogTitle: string;
  public apiBody: any;
  public proceedActionButtonTitle: string;
  public actionType: string;
  public individualFileDetails: IndividualFileDetails[] = [];

  constructor(dialogTitle: string,apiBody: any,proceedActionButtonTitle: string,actionType: string,individualFileDetails: IndividualFileDetails[] = []) {
    this.dialogTitle = dialogTitle;
    this.apiBody = apiBody;
    this.proceedActionButtonTitle = proceedActionButtonTitle;
    this.actionType = actionType;
    this.individualFileDetails = individualFileDetails;
  }
}


export class IndividualFileDetails {
  public fileName: string;
  public shouldCheckForValidFileName: boolean;
  public file: any | null = null;
  public fileType: string
}

export const FileTypes = {
  xls: "application/vnd.ms-excel",
  csv: "text/csv",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
}