import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ValidatePlacementDialogComponent } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-break-bulk/bl-validate-placement/validate-placement-dialog/validate-placement-dialog.component';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';
import { ProvisionalFCLOrdersService } from 'src/app/xp-admin-web/ordernew/manage-orders/fcl-orders/provisional-fcl-orders/provisional-fcl-orders.service';
import { FfvDetailsService } from 'src/app/xp-admin-web/users/ffv2/ffv-details.service';
import { VendorService } from 'src/app/xp-admin-web/users/vendors/vendor.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-lcl-validate-placement-dispatch',
  templateUrl: './lcl-validate-placement-dispatch.component.html',
  styleUrls: ['./lcl-validate-placement-dispatch.component.scss']
})
export class LclValidatePlacementDispatchComponent implements OnInit {
  manifestId: any;
  manifest: any;
  formControl: FormGroup;
  today: Date;
  TodayTime: Date;
  AllDrivers: any = [];
  constructor(public dialogRef: MatDialogRef<LclValidatePlacementDispatchComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public fb: FormBuilder, public lclTranshipmentService: LclTranshipmentService, public alertService: AlertService, public datepipe: DatePipe, private _proOrdersService: ProvisionalFCLOrdersService, public dialog: MatDialog,
    private _vendorService: VendorService,
    private _FfvDetailsService: FfvDetailsService) {
    this.formControl = this.fb.group({
      PlacementDate: new FormControl('', Validators.required),
      PlacementTime: new FormControl('', Validators.required),

      FFVId: new FormControl(null),
      ManifestId: new FormControl(null),
      DriverId: new FormControl(null),
      FFVName: new FormControl(''),
      DriverName: new FormControl(''),
      DriverNo: new FormControl(''),
      DlNumber: new FormControl('',Validators.required),
      DriverDOB: new FormControl(null),
      DlClass: new FormControl(''),
      ValidUpto: new FormControl(''),
      Address: new FormControl(''),
      VehicleNumber:new FormControl(''),
      CustomerName:new FormControl('')
    });
  }
  DL_Number: any;
  DLVerified = false;
  showdate = false;
  XPTSDate:any;
  VehicleType:any;
  Route:any;
  VehicleNumber;
  public minDate : any

  ngOnInit(): void {
    if (this.data != null) {
      this.manifestId = this.data['ManifestId'];
      this.manifest = this.data['XPTSNo'];
      this.DL_Number = this.data['DLNumber'];
      this.XPTSDate=this.datepipe.transform(this.data['Date'],'dd-MM-y');
      this.minDate = new Date(this.datepipe.transform(this.data['Date'],'yyyy-MM-dd HH:mm'));
    
      if(this.data['Via1GatewayName'] != null && this.data['Via2GatewayName'] == null){
        this.Route=this.data['Origin']+' - '+this.data['Via1GatewayName']+' - '+this.data['Destination'];
      }
      else if(this.data['Via1GatewayName'] == null && this.data['Via2GatewayName'] != null){
        this.Route=this.data['Origin']+' - '+this.data['Via2GatewayName']+' - '+this.data['Destination'];
      }
       else if(this.data['Via1GatewayName'] == null && this.data['Via2GatewayName'] == null){
        this.Route=this.data['Origin']+' - '+this.data['Destination'];
      }
      else{
        this.Route=this.data['Origin']+' - '+this.data['Via1GatewayName']+' - '+this.data['Via2GatewayName']+' - '+this.data['Destination'];
      }
      //this.Route=this.data['Origin']+' - '+this.data['Destination'];
      this.VehicleType=this.data['VehicleType'];
      this.VehicleNumber=this.data['VehicleNo'];

      //this.formControl.controls['DlNumber'].setValue(this.data.DL_Number);
      this.formControl.controls['DriverName'].setValue(this.data['DriverName'].split('(')[0]);
      this.formControl.controls['FFVName'].setValue(this.data['FFVName']);
      this.formControl.controls['DriverNo'].setValue(this.data['DriverNo']);
      this.formControl.controls['FFVId'].setValue(this.data['FFVId']);
      this.formControl.controls['ManifestId'].setValue(this.data['ManifestId']);
      this.formControl.controls['VehicleNumber'].setValue(this.data['VehicleNo']);

    }

    this.today = new Date();
    this.TodayTime = new Date();

  }

  close(): void {
    this.dialogRef.close();
  }

  validatePlacement() { 
    const ValidateTime = new Date(this.datepipe.transform(this.formControl.controls['PlacementDate'].value,'yyyy-MM-dd HH:mm'));
    const Today = new Date(this.datepipe.transform(this.today,'yyyy-MM-dd HH:mm'));
    
    
    //if(ValidateTime <= Today && ValidateTime >= this.minDate){
    if (this.formControl.controls['PlacementDate'].valid && this.manifestId != null && this.manifestId != "" && typeof (this.manifestId) != 'undefined'
    &&  this.formControl.controls['DlNumber'].value != null
    ) {
      let placementdate = this.datepipe.transform(this.formControl.controls['PlacementDate'].value, 'yyyy-MM-dd');
      let time = this.datepipe.transform(this.formControl.controls['PlacementTime'].value, 'HH:mm');
      var datetime = this.datepipe.transform(placementdate + ' ' + time, 'yyyy-MM-ddTHH:mm:ss.sssZ');

      // Convert to ISO string
       datetime = new Date(datetime).toISOString();
      this.formControl.controls['PlacementDate'].setValue(datetime);
      this.formControl.controls['DriverName'].enable()
      this.formControl.controls['DlNumber'].enable()
      //this.formControl.controls['DriverNo'].enable()
      this.formControl.controls['DriverDOB'].enable()

      if(this.DriverChanged == true){
        this.addDriver()
      }

      this.lclTranshipmentService.validatePlacementForManifestByIndentCore(this.formControl.value).subscribe(res => {
        this.alertService.createAlert(res['Message'], res['Success'] ? 1 : 0)
        this.close()
      })
    }
    else if(this.formControl.controls['DlNumber'].value.trim().includes(' ') || this.formControl.controls['DlNumber'].value == null){
      this.alertService.createAlert("Please Enter DL Number",0)
    }
    else if(this.formControl.controls['PlacementDate'].invalid){
      this.alertService.createAlert("Please select placement Date & Time",0)
    }
  // }else{
  //   this.alertService.createAlert("Placement date should be between "+this.datepipe.transform(this.minDate, "yyyy-MM-dd HH:mm")+" and "+this.datepipe.transform(this.today, "yyyy-MM-dd HH:mm"),0)
  // }

  }



  GetDriverDetails() {
    if (this.formControl.controls['DriverDOB'].value == '' || this.formControl.controls['DlNumber'].value == '') {
      this.alertService.createAlert("Please select DL/DOB", 0);
    }
    else {

      let datavalue = {
        "id_number": this.formControl.controls['DlNumber'].value,
        "dob": this.datepipe.transform(this.formControl.controls['DriverDOB'].value, 'yyyy-MM-dd')
      }
      this._FfvDetailsService.validatedriverDL(datavalue).subscribe(data => {
        //console.log(data);
        if (data['success'] == true) {
          this.formControl.controls.DriverName.setValue(data['data'].name);
          this.formControl.controls.DlClass.setValue(data['data'].vehicle_classes[0]);
          this.formControl.controls.ValidUpto.setValue(new Date(this.datepipe.transform(data['data'].doe, 'yyyy-MM-dd')));
          this.formControl.controls.Address.setValue(data['data'].permanent_address);
          this.DLVerified = true;

          this.formControl.controls['DriverName'].disable()
          //this.formControl.controls['DriverNo'].disable()
          this.formControl.controls['DlNumber'].disable()
          this.formControl.controls['DriverDOB'].disable()

          this.DriverChanged = true

          //console.log(this.formControl.value)

        }
        else {
          this.alertService.createAlert("DL Number/DOB is Invalid", 0);

        }
      },
        (error) => {
          this.alertService.createAlert("DL Number/DOB is Invalid", 0);
        });
    }
  }


  filteredList: any;
  DriverAvailable: boolean = true;
  DriverChanged: boolean = false;

  filterdriver() {
    let DLNo = this.formControl.controls['DlNumber'].value.trim()
    this._vendorService.getFfvDriversDLNo(DLNo).subscribe((res: any) => {
      this.filteredList = res.Data.Drivers;

      if (this.filteredList.length > 0) {
        this.showdate = false
        if (this.filteredList[0].IsAvailable == true) {
          this.DriverAvailable = true
          this.alertService.createAlert("Driver Verified Successfully",1)
        }
        else {
          if(this.DL_Number == this.filteredList[0].DlNumber){
            this.DriverAvailable = true
            this.alertService.createAlert("Driver Verified Successfully",1)
          }
          else{
            this.DriverAvailable = false
          }
        }

        if(this.DL_Number == this.filteredList[0].DlNumber){
          this.DriverChanged = false
        }
        else{
          this.DriverChanged = true
        }

        this.formControl.controls['DlNumber'].setValue(this.filteredList[0].DlNumber);
        this.formControl.controls['DriverName'].setValue(this.filteredList[0].DriverName);
        this.formControl.controls['DriverNo'].setValue(this.filteredList[0].PhoneNumber);
        this.formControl.controls['DriverId'].setValue(this.filteredList[0].DriverId);
        this.formControl.controls['FFVId'].setValue(this.filteredList[0].FfvId);

      }
      else {
        this.showdate = true;
        this.DriverAvailable = true
        //this.formControl.controls['DlNumber'].setValue(null);
        this.formControl.controls['DriverName'].setValue(null);
        this.formControl.controls['DriverNo'].setValue(null);
        this.formControl.controls['DriverId'].setValue(null);
        //this.GetDriverDetails()
      }

    })
  }

  addDriver() {
    if (this.formControl.controls['PlacementDate'].valid && this.manifestId != null && this.manifestId != "" && typeof (this.manifestId) != 'undefined') {
      
      this.lclTranshipmentService.UpsertDriverLCLValidatePlacement(this.formControl.value).subscribe(data => {

      })

    }
    else{
      this.alertService.createAlert("Please select placement Date & Time",0)
    }
  }

}
