import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LclPickUpService } from '../../xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { OrderDialogComponent } from 'src/app/shared/order-dialog/order-dialog.component';
import { LclBookingIdDialogBoxComponent } from 'src/app/shared/lcl-booking-id-dialog-box/lcl-booking-id-dialog-box.component';
import { XpcnDetailsDialogComponent } from 'src/app/shared/xpcn-details-dialog/xpcn-details-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DetailsXpcnComponent } from 'src/app/shared/details-xpcn/details-xpcn.component';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dialog-manifest-detail',
  templateUrl: './dialog-manifest-detail.component.html',
  styleUrls: ['./dialog-manifest-detail.component.scss']
})
export class DialogManifestDetailComponent implements OnInit {

  manifestId;
  screenname: boolean;
  vehicleNo: any;
  xptscode: any;
  screen: any;
  excelData: any[];
  BookingCount: Number;
  DispatchTrip: boolean;
  status:any;
  OrderDetails:any;
  manifestDetails = [];

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any,
    private _lclPickup_service: LclPickUpService, private _datePipe: DatePipe,public dialog: MatDialog, private alertService: AlertService, private _downloadExcelService: DownloadExcelService) { }

  ngOnInit(): void {
    this.vehicleNo = this.data.vehicleNo;
    this.xptscode = this.data.xptscode;
    this.screen = this.data.screen;
    this.BookingCount = this.data.BookingCount;
    this.status = this.data.status;

    if (this.data.screen == 'tripsheet') {
      this.screenname = true
      this.manifestId = this.data['data'];
      this.DispatchTrip = false;
    }
    else if (this.data.screen == 'allocatevehicle') {
      this.screenname = true;
      this.manifestId = this.data.data['ManifestId']
      this.DispatchTrip = false;
    }
    else if (this.data.screen == 'assign-vehicle') {
      this.screenname = true;
      this.manifestId = this.data['xptscode']
      this.xptscode = this.data['vcxptscode']
      this.DispatchTrip = false;
    }
    else if (this.data.screen == 'manifest') {
      this.screenname = true;
      this.manifestId = this.data['manifestId'];
    }
    else if (this.data.screen == 'proptripsheet'){
      this.screenname = true;
      this.manifestId = this.data['manifestId'];
      this.DispatchTrip = false;
      this.OrderDetails = this.data.res.Data[0].ManifestDetails
    }
    else if (this.data.screen == 'closed_arrived_trip') {
      this.screenname = true;
      this.manifestId = this.data['manifestId'];
      this.DispatchTrip = false;
    }
    else if (this.data.screen == 'inbound-booking-arrival') {
      this.screenname = true;
      this.manifestId = this.data['ManifestId'];
      this.DispatchTrip = false;
    }
    else if (this.data.screenName == 'bookingTrip') {
      this.manifestId = this.xptscode
      this.DispatchTrip = true;
    }

    else {
      this.screenname = false
      this.manifestId = this.data['data'];
    }
    this.getManifestDetails();
  }


 
  getManifestDetails() {
    if(this.data.screen != 'proptripsheet'){
      this._lclPickup_service.getmenifistdailogdetails(this.manifestId).subscribe(data => {
        if (data != null && data['Success']) {
          this.manifestDetails = data['Data']['OrderAssignments'];
        }
      })
    }
    
  }

  close() {
    this.dialogRef.close();
  }
  showAll:boolean=false;
  showAllXpcn() {
    this.showAll = !this.showAll;
    
  }

  public openOrder(item) {
    const dialogRef = this.dialog.open(OrderDialogComponent, {
      data: { item },
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(d => {
      // let data = localStorage.getItem('OrdersDashBoard');
      // if (data != null && data != '') {
      //   this.savedHeaders = JSON.parse(data);
      // }
    });
  }

  openDialog(BookingId) {
    this._lclPickup_service.getAllXPCNDetails(BookingId).subscribe((data) => {
      const dialogRef = this.dialog.open(DetailsXpcnComponent, {
        data: { screename: "ExpressFFVAllocation", apiData: data },
        height: 'auto',
        width: '1400px',
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(() => {

      });
    });
  }

  // openConfirmDialog(bookingid, ManifestId, intBookingId) {
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
  //     data: {
  //       reason:'Reason',
  //       message: `Do you want to remove ${bookingid} from this XPTS ?`,
  //       api: `api/remove-unpicked-booking-from-xpts?bookingId=${intBookingId}&xptsId=${ManifestId}`,
  //       buttonText: {
  //         ok: 'Confirm',
  //         cancel: 'No'
  //       } 
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe((confirmed: boolean) => {
  //     if (confirmed == true) {
  //       this.getManifestDetails();
  //     }
  //   });
  // }

  public exportAsExcel() {
    this.excelData = [];
    if (this.manifestDetails.length > 0) {
      this.manifestDetails.forEach(element => {
        this.excelData.push({
          'Manifest No.': element['ManifestCode'],
          'Booking No.': element['VCBookingId'],
          'Booking Date': this._datePipe.transform(element['booking_date'], 'dd-MM-y'),
          // 'No Of Order IDs': element['NumberOfOrder'],
          'XPCNs No': this.getXPCN(element['XPCNDetails']),
          'XPCN Date': this.getXPCNDate(element['XPCNDetails']),
          'Customer': element['CustomerName'],
          'Consignor Name': element['vc_consignor'],
          'No of Boxes': element['Boxes'],
          'Actual Weight': element['ActualWeight'],
          'Charged Weight': element['ChargedWeight'],
          'Picked Date & Time': this._datePipe.transform(element['PickedDate'], 'dd-MM-yyyy (HH:mm)'),
          'Status': element['OrdersStatus'],
         

        })
      });
    } else {
      this.excelData.push({
        'Manifest No.': '',
          'Booking No.': '',
          'No Of Order IDs': '',
          'XPCNs No': '',
          'XPCN Date':'',
          'Customer': '',
          'Consignor Name':'',
          'No of Boxes': '',
          'Actual Weight': '',
          'Charged Weight':'',
          'Picked Date & Time': '',
          'Status':''
      })
    }
    this._downloadExcelService.exportAsExcelFile(this.excelData, 'lcl-linehaul');
  }


  public getXPCN(data: []) {
    if (data == null) {
      return "";
    }
    let str = ""
    data.forEach(element => {
      str = str + element['XPCNCode'] + ", ";
    })
    return str
  }

  public getXPCNDate(data: []) {
    if (data == null) {
      return "";
    }
    let str = ""
    data.forEach(element => {
      str = str + this._datePipe.transform(element['XPCNDate'], 'dd-MM-yyyy (HH:mm)') + ", ";
    })
    return str
  }
}
