import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private _http: HttpClient
  ) { }

  // to get list of roles
  getRoles() {
    return this._http.get(environment.apiUrl + 'api/get-roles');
  }

  // to save roles
  saveRoles(values) {
    return this._http.post(environment.apiUrl + 'api/upsert-roles', values);
  }

  // to delete roles
  deleteRoles(values) {
    return this._http.post(environment.apiUrl + 'api/delete-roles', values);
  }

  // to get the list of users
  getUsers() {
    return this._http.get(environment.apiUrl + 'api/get-users');
  }

  // to upsert user
  upsertUser(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-user', data);
  }

  // to update user status
  updateUserStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-user-status', data);
  }

  // to delete user
  deleteUser(data) {
    return this._http.post(environment.apiUrl + 'api/delete-user', data);
  }

  // to update user admin status
  updateAdminStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-user-admin-status', data);
  }

  // to reset user password
  resetUserPassword(data) {
    return this._http.post(environment.apiUrl + 'api/reset-user-password', data);
  }

  // to reset consignor password
  resetConsignorPassword(data) {
    return this._http.post(environment.apiUrl + 'api/reset-consignor-password', data);
  }

  // to get the list of role permissions
  getRolePermissions(RoleId) {
    return this._http.get(environment.apiUrl + 'api/get-role-permissions?RoleId=' + RoleId);
  }

  // to save role permissions
  saveRolePermissions(data) {
    return this._http.post(environment.apiUrl + 'api/save-role-permissions', data);
  }

  // to get the list of user zone branch permissions
  getUserZoneBranchPermissions(userId,permissionType) {
    return this._http.get(environment.apiUrl + 'api/get-user-zone-branch-permissions?userId=' + userId+'&permissionType='+permissionType);
  }

  // to save role permissions
  saveUserZoneBranchPermissions(data) {
    return this._http.post(environment.apiUrl + 'api/save-user-zone-branch-permissions', data);
  }

  // to get role history data
  getRoleHistory(roleId) {
    return this._http.get(environment.apiUrl + 'api/get-role-history/' + roleId);
  }

  // to get user history data
  getUserHistory(userId) {
    return this._http.get(environment.apiUrl + 'api/get-user-history/' + userId);
  }

  // to update user permission type
  updatePermissionType(data) {
    return this._http.post(environment.apiUrl + 'api/update-user-permission-type', data);
  }

  // to get user customer permissions
  getUserCustomerPermissions(userId,permissionType) {
    return this._http.get(environment.apiUrl + 'api/get-user-customer-permissions?userId=' + userId+'&permissionType='+permissionType);
  }

  // to get user customer permissions
  getUserGatewayPermissions(userId,permissionType) {
    return this._http.get(environment.apiUrl + 'api/get-user-gateway-permissions?userId=' + userId+'&permissionType='+permissionType);
  }

  // to save user customer permission
  saveUserCustomerPermissions(data) {
    return this._http.post(environment.apiUrl + 'api/save-user-customer-permissions', data);
  }

  // to save user customer permission
  saveUserGatewayPermissions(data) {
    return this._http.post(environment.apiUrl + 'api/save-user-gateway-permissions', data);
  }
  
}
