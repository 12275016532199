import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ApprovalsService } from '../../xp-admin-web/approvals/approvals.service';
import { MatDialogModule } from '@angular/material/dialog';


@Component({
  selector: 'app-ffv-approval-dialog',
  templateUrl: './ffv-approval-dialog.component.html',
  styleUrls: ['./ffv-approval-dialog.component.scss']
})
export class FfvApprovalDialogComponent implements OnInit {
  formControl: FormGroup;
  constructor(public fb: FormBuilder, public dialogRef: MatDialogRef<FfvApprovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public _alertService: AlertService, private dialog: MatDialog,
    private _datePipe: DatePipe, private _apprvalService: ApprovalsService) {
    this.formControl = this.fb.group({

      dec_freight: new FormControl(''),
      dec_halting_charges: new FormControl(''),
      dec_other_charges: new FormControl(''),
      dec_delay_penality: new FormControl(''),
      dec_handling_charges: new FormControl(''),
      vc_remark: new FormControl(''), 
      TripNo: new FormControl(''),
    })
  }

  OrderData = [];
  XPTSNo: any;
  XPTSDATE: any;
  ScreenName: any;
  freightDisable = false;

  ngOnInit(): void {
   // console.log(this.data['data']);
    this.ScreenName = this.data['Screen']
    this.OrderData = this.data['data'];
    this.XPTSNo = this.OrderData['TripNo'];
    this.XPTSDATE = this._datePipe.transform(this.OrderData['dt_xptsDate'], 'dd-MM-yyyy');
    this.formControl.controls['TripNo'].setValue(this.OrderData['TripNo']);
    this.formControl.controls['dec_freight'].setValue(this.OrderData['dec_freight']);
    this.formControl.controls['dec_halting_charges'].setValue(this.OrderData['dec_halting_charges']);
    this.formControl.controls['dec_handling_charges'].setValue(this.OrderData['dec_handling_charges']);
    this.formControl.controls['dec_delay_penality'].setValue(this.OrderData['dec_delay_penality']);
    this.formControl.controls['dec_other_charges'].setValue(this.OrderData['dec_other_charges']);

    if (this.data['Screen']=='totalFreight')
      {
this.freightDisable = true
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSave() {
    if (this.ScreenName === 'FirstApproval')
    //Frist Start
    {
      if (this.formControl.controls['dec_freight'].valid
      
      // && this.formControl.controls['dec_halting_charges'].valid 
      // && this.formControl.controls['dec_delay_penality'].valid && this.formControl.controls['dec_other_charges'].valid 
      // && this.formControl.controls['dec_handling_charges'].valid
      
      && this.formControl.controls['TripNo'].valid) {
        this._apprvalService.UpdateLCLFreightByFirstApproval(this.formControl.value).subscribe(data => {
          if (data['Success']) {
            this._alertService.createAlert('Updated Succesfully...!', 1);
            this.close();
          }
        });
      }
      else {
        this._alertService.createAlert('Failed', 0);
      }
    }
    // First End

    //Second Start
    else if (this.ScreenName === 'SecondApproval') {
      if (this.formControl.controls['dec_freight'].valid 
      
      // && this.formControl.controls['dec_halting_charges'].valid && this.formControl.controls['dec_other_charges'].valid && this.formControl.controls['dec_delay_penality'].valid && this.formControl.controls['dec_handling_charges'].valid 
      
      && this.formControl.controls['TripNo'].valid) {
        this._apprvalService.UpdateLCLFreightBySecondApproval(this.formControl.value).subscribe(data => {
          if (data['Success']) {
            this._alertService.createAlert('Updated Succesfully...!', 1);
            this.close();
          }
        });
      }
      else {
        this._alertService.createAlert('Failed', 0);
      }
    }
    // Second End


    //Final Start
    else if (this.ScreenName === 'FinalApproval') {
      if (this.formControl.controls['dec_freight'].valid 
      
      // && this.formControl.controls['dec_halting_charges'].valid && this.formControl.controls['dec_other_charges'].valid && this.formControl.controls['dec_delay_penality'].valid && this.formControl.controls['dec_handling_charges'].valid 
      
      && this.formControl.controls['TripNo'].valid) {
        this._apprvalService.UpdateLCLFreightByFinalApproval(this.formControl.value).subscribe(data => {
          if (data['Success']) {
            this._alertService.createAlert('Updated Succesfully...!', 1);
            this.close();
          }
        });
      }
      else {
        this._alertService.createAlert('Failed', 0);
      }
    }
    // Final End

  }



}
