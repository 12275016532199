export class DeliveryConstantsForFN{
    public readonly COD : number = 1
    public readonly POD : number = 0
    public readonly Generate :  string = 'Generate'
    public readonly Dispatch :  string = 'Dispatch'
    public readonly Receipt :  string = 'Receipt'
    public readonly Preview :  string = 'Preview'
    public readonly Scan :  string = 'Scan'
}

export enum FormFields {
    CourierName = 'CourierName',
    AwbNo = 'AwbNo',
    Date = 'DispachedDate',
    AwbUrl = 'AwbUrlForUpdate',
    FNoteId = 'FNoteId'
  }