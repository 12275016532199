import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { interval, Subscription } from 'rxjs';
import { ApprovalsService } from 'src/app/xp-admin-web/approvals/approvals.service';

@Component({
  selector: 'app-otp-dialog-box',
  templateUrl: './otp-dialog-box.component.html',
  styleUrls: ['./otp-dialog-box.component.scss']
})
export class OtpDialogBoxComponent implements OnInit {

  otp: string = '';
  timeoutWidth: number = 0; // Percentage width of the timeout line
  private timeoutSubscription: Subscription = new Subscription();
  private readonly timeoutDuration: number = 30; // Timeout duration in seconds
  paymentIds : string
  private timerStarted: boolean = false;
  
  constructor(private _approvalService: ApprovalsService, @Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<OtpDialogBoxComponent>,
)
   { }

  ngOnInit(): void {
    this.paymentIds = this.data
    .map(record => record.PaymentID)  // Extract PaymentID values
    .join(',');
    console.log(this.paymentIds);

  }

  ngOnDestroy(): void {
    this.timeoutSubscription.unsubscribe();
  }

  startTimeout(): void {
    if (this.timerStarted) return; // Prevent multiple timers

    this.timerStarted = true;
    const totalMilliseconds = this.timeoutDuration * 1000;
    const intervalMilliseconds = 1000; // Update every second
    let elapsedMilliseconds = 0;

    this.timeoutSubscription = interval(intervalMilliseconds).subscribe(() => {
      elapsedMilliseconds += intervalMilliseconds;
      this.timeoutWidth = (elapsedMilliseconds / totalMilliseconds) * 100;

      if (elapsedMilliseconds >= totalMilliseconds) {
        this.timeoutSubscription.unsubscribe();
        this.timeoutWidth = 0; // Reset the timeout line
        this.timerStarted = false; // Re-enable the button
      }
    });
  }


  GetOTP() {
    this.startTimeout();

    this._approvalService.SendOTPForFFVPayment(this.paymentIds).subscribe(res => {
      if (res != null) {
        res['Success'] = true;  
      }
    });
  }
  
  
    InitiatePayment() {
    
        this._approvalService.InitiatePaymentforVendor(this.paymentIds, this.otp).subscribe(res => {
          if(res['Success']){
            this.dialogRef.close();
          }
        });
    }
  
    onKeyPress(event: KeyboardEvent) {
      const allowedCharacters = /^[0-9]*$/; // Regular expression to allow only numeric characters
    
      // Allow keypress only if the entered character is a number or a control key (like backspace)
      if (!allowedCharacters.test(event.key) && !event.ctrlKey && !event.metaKey && !event.altKey) {
        event.preventDefault();
      }
    } 
    onInput(event: Event): void {
      const input = event.target as HTMLInputElement;
    
      // Remove non-numeric characters and ensure the value does not exceed 6 digits
      let sanitizedValue = input.value.replace(/[^0-9]/g, '');
      if (sanitizedValue.length > 6) {
        sanitizedValue = sanitizedValue.slice(0, 6); // Trim to 6 digits
      }
    
      // Update the input value and the model
      input.value = sanitizedValue;
      this.otp = sanitizedValue;
    }

    onCancel(): void {
      this.dialogRef.close(); // Close the dialog
    }
}
