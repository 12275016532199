import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailValidator } from '../theme/utils/app-validators';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { AlertService } from '../shared/services/alert.service';
import { LoginService } from './login.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [AlertService]
})
export class LoginComponent {
  public TypeString: string = "password";
  public isPassword: boolean = true;
  loginData = {
    Type: 1,
    Email: '',
    Password: '',
    DeviceId: null,
  };
  filters = [];

  public form: FormGroup;
  public settings: Settings;
  constructor(public appSettings: AppSettings, public fb: FormBuilder, public router: Router, public alertService: AlertService,
    private _loginService: LoginService) {
    this.settings = this.appSettings.settings;
    this.form = this.fb.group({
      'email': [null, Validators.compose([Validators.required])],
      'password': [null, Validators.compose([Validators.required, Validators.minLength(6)])]
    });
  }

  public onSubmit(values: Object): void {
    if (this.form.valid) {
      localStorage.clear();
      sessionStorage.clear();
      // this.loginData.Email = CryptoEncriptionService.encryptString(values['email'].trim());
      // this.loginData.Password = CryptoEncriptionService.encryptString(values['password'].trim());
      //console.log(CryptoEncriptionService.decryptString(this.loginData.Email),CryptoEncriptionService.decryptString(this.loginData.Password))
      
      this.loginData.Email = values['email']
      this.loginData.Password = values['password']
      this.loginData.DeviceId = localStorage.getItem('deciveId');
      this._loginService.login(this.loginData).subscribe(data => {
        if (data != null) {
          if (data['Success']) {
            localStorage.removeItem('userSession');
            // console.log(data['Data']);
            localStorage.setItem('userSession', JSON.stringify(data['Data']));
            localStorage.setItem('Filters', JSON.stringify(this.filters));

            var branches = JSON.parse(localStorage.getItem('userSession'))['Branches'];
            if (branches != null && branches != undefined && branches.length > 0) {
              localStorage.setItem('operationsBranchId', branches[0]['BranchId']);
            }
            if (data['Data']['IsAssociate']) {
              // this.router.navigate(['/xp/user/associates/payments']);
                this.router.navigate(['/xp']);
            } else if (data['Data']['Permissions'].length > 0 || data['Data']['IsAdmin']) {
              if (data['Data']['UserType'] === 'User') {
                this.router.navigate(['/xp']);
              } else {
                this.router.navigate(['/xp/customerdashboard']);
              }
            } else {
              this.router.navigate(['/xp/no-permissions']);
            }
            // this.alertService.createAlert(data['Message'], 1);
          } else {
            // this.alertService.createAlert(data['Message'], 0);
          }
        }
      });
      // if (values['email'] == 'admin@xpindia.com' && values['password'] == 'Password1!1') {
      //   this.router.navigate(['/xp']);
      //   this.alertService.createAlert('Login Success', 1);
      //   localStorage.setItem('loginUser', values['email']);
      // } else if (values['email'] == 'vendor@vendor.com' && values['password'] == 'Password1!1') {
      //   this.router.navigate(['/xp/fleetDashboard']);
      //   this.alertService.createAlert('Login Success', 1);
      //   localStorage.setItem('loginUser', values['email']);

      // } else if (values['email'] == 'customer@xpindia.com' && values['password'] == 'Password1!1') {
      //   this.router.navigate(['/xp/customerdashboard']);
      //   this.alertService.createAlert('Login Success', 1);
      //   localStorage.setItem('loginUser', values['email']);

      // } else {
      //   this.alertService.createAlert('Invalid Credentials', 0);
      // }
    }
  }

  public ChangetextType(isPassword) {
    if (isPassword) {
      this.TypeString = "password"
    } else {
      this.TypeString = "text"
    }
  }

  // ngAfterViewInit(){
  //   this.settings.loadingSpinner = false; 
  // }

  ngAfterViewInit() {
    setInterval(() => {
      this.settings.loadingSpinner = false;
    }, 500)
  }
}