import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LclDeliveryService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-delivery/lcl-delivery.service';
import { AlertService } from '../services/alert.service';
import { DatePipe } from '@angular/common';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { FormBuilder } from '@angular/forms';
import { LclXpcnPreviewDialogComponent } from '../lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';

@Component({
  selector: 'app-dialog-lcl-delivery-pod-log',
  templateUrl: './dialog-lcl-delivery-pod-log.component.html',
  styleUrls: ['./dialog-lcl-delivery-pod-log.component.scss']
})
export class DialogLclDeliveryPodLogComponent implements OnInit {

  VehicleNo:any;
  Gateway:any;
  XptsNo:any;
  xpts_date:any;
  OrderTable=[];
  isDerectDelivery:boolean;


  constructor(public dialogRef: MatDialogRef<DialogLclDeliveryPodLogComponent>,
    private lcldeliveryService: LclDeliveryService, private alertService: AlertService, public _datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public fb: FormBuilder,public _lclPickService:LclPickUpService) { }

  ngOnInit(): void {
    debugger
    this.data.data;
    this.VehicleNo=this.data.data.VehicleNo || this.data.data.VehicleNumber;
    this.XptsNo=this.data.data.TripNo || this.data.data.TripsheetNumber || this.data.data.xpts_no;
    this.isDerectDelivery=this.data.bt_IsDerectDelivery;
    this.GetOrders();
  }

GetOrders()
{
  this.lcldeliveryService.GetAllDeliveryPODLogDetails(this.data.data.TripId || this.data.data.int_TripId,this.data.bt_IsDerectDelivery).subscribe(data=>{
    this.OrderTable=data['Data'];
  })
}

  close(): void {
    this.dialogRef.close();
  }

  ViewPod(URL)
  {
    window.open("https://xpindiadocuments.s3.ap-south-1.amazonaws.com" + URL, 'POD 1');
  }

  confirm()
  {

  }

  openLCLXPCNPreview(XPCNId) {
    this._lclPickService.getLCLXPCNDetailsForFinzalization(XPCNId).subscribe(data => {
      if (data != null && data['Data'] != null) {
        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;
        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }


}
