import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { environment } from 'src/environments/environment';
import { DialogEwayBillComponent } from '../dialog-eway-bill/dialog-eway-bill.component';
import { DialogInvoiceComponent } from '../dialog-invoice/dialog-invoice.component';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { XpcnDetailsDialogComponent } from '../xpcn-details-dialog/xpcn-details-dialog.component';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';
import { LclXpcnPreviewDialogComponent } from '../lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';
import { AlertService } from '../services/alert.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-manifest-detail-dialog',
  templateUrl: './manifest-detail-dialog.component.html',
  styleUrls: ['./manifest-detail-dialog.component.scss']
})
export class ManifestDetailDialogComponent implements OnInit {
  bookingId
  DbBookingId;
  manifest;
  isDeleted: boolean = false;
  EwayBillArray: any[];
  excelData: any[];
  VehicleNo: any;
  Origin: any;
  Destination: any;
  Via1: any;
  Via2: any;
  route: any;
  VcScreen : any
  isSubManifest : boolean = false
  DeliveryGateway: any;

  constructor(public dialogRef: MatDialogRef<any>, 
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private _lclPickup_service: LclPickUpService, 
    private dialog: MatDialog, private _downloadExcelService : DownloadExcelService, 
    private _lclTranshipmentService : LclTranshipmentService,public alertService : AlertService,
    private _datePipe : DatePipe) { }

  int_Manifest_id: Number;
  ngOnInit(): void {
    //This if condition is only used for Unloading screen(direct delivery)
    if(this.data.item.ServiceType == "LCL Dispatch DD"){
      this.int_Manifest_id = this.data.DBTallyNo || this.data.item.int_tally_id || this.data.item.TallyId;
    }else{
    this.Origin = this.data.Origin
    this.Destination = this.data.Destination
    this.Via1 = this.data.Via1
    this.Via2 = this.data.Via2
    // this.route =
    // this.Via1 === null && this.Via2 === null
    // ? this.Origin + '-' + this.Destination
    // : this.Via1 !== null && this.Via2 === null
    // ? this.Origin+ '-'+ this.Via1+ '-'+ this.Destination
    // : this.Origin+ '-' +this.Via1+ '-' +this.Via2+ '-' +this.Destination;
    this.route = this.Origin + '-' + this.Destination
    if (this.data.item.ServiceType != "LCL Direct - Delivery" && (this.data.item != undefined || null)) {
      this.int_Manifest_id = this.data.item.int_manifest || this.data.item.int_manifest_id ||  this.data.item.XptsId;
      this.isSubManifest = this.data.isSubManifest
    }
    else {
      
      this.int_Manifest_id = this.data.item.int_manifest_id || this.data.item.TallyId|| this.data.DBTallyNo || this.data.item.int_tally_id;
    
    }
  }
    this.getManifestDetail();
  }

  manifestDetails = [];

  getManifestDetail() {
    if(this.data.isDisptachManifestDetails){
      
      this._lclPickup_service.getManifestDetailsDispatch(this.int_Manifest_id,this.isSubManifest).subscribe(data => {
        this.manifestDetails = data['Data']
        this.manifest = this.manifestDetails[0]['strManifestId'];
        this.VehicleNo = this.manifestDetails[0]['VehicleNo']
        //this.Origin = this.manifestDetails[0]['Origin']
        //this.Destination = this.manifestDetails[0]['Destination']
        this.DeliveryGateway = this.manifestDetails[0]['DeliveryGateway']
        this.VcScreen = 'Dispatch Manifest Details'
        this.calculateTotal();
  
      })
    }
    else{
      this._lclPickup_service.getManifestDetails(this.int_Manifest_id).subscribe(data => {
        this.manifestDetails = data['Data']
        this.manifest = this.manifestDetails[0]['strManifestId'];
        this.VehicleNo = this.manifestDetails[0]['VehicleNo']
        this.Origin = this.manifestDetails[0]['Origin']
        this.Destination = this.manifestDetails[0]['Destination']
        this.VcScreen = null
        this.calculateTotal();
      })
    }
   

  }



  close() {
    this.dialogRef.close();
  }


  openInvoiceDetails(item) {
    const dialogRef = this.dialog.open(DialogInvoiceComponent, {
      data: { item },
      height: 'auto',
      width: '600px',
      autoFocus: false
    })
    dialogRef.afterClosed().subscribe(d => {

    })
  }


  openEwayBillDetails(item) {
    this._lclPickup_service.getEwayBill(item.XPCNId).subscribe(data => {
      this.EwayBillArray = data['Data']
      const dialogRef = this.dialog.open(DialogEwayBillComponent, {
        data: {data:data['Data'], vehicleNo:this.VehicleNo},
        height: 'auto',
        width: '600px',
        autoFocus: false
      })
      dialogRef.afterClosed().subscribe(d => {

      })
    })
  }

  download() {
    window.open(environment.apiUrl + "InvoiceView/DownloadManifestPDF?int_id=" + this.int_Manifest_id + '&vc_mnaifest=' + this.manifest + '&vc_screen=' + this.VcScreen, "_blank");
  }
 
  downloadCSV() {
    this.excelData = [];
    
    if(this.data.isDisptachManifestDetails){
      this._lclPickup_service.getManifestDetailsDispatch(this.int_Manifest_id,this.isSubManifest).subscribe(data => {
        if (data['Data'].length > 0) {
            data['Data'].forEach(element => {
                this.excelData.push({
                  'Manifest No' : element['strManifestId'],
                  'Booking No' : element['strBookingId'],
                  // 'Status' : element['status'],
                  'Order Id' : element['OrderId'],
                  'XPCN' : element['XPCNNo'],
                  'XPCN Date' : this._datePipe.transform(element["dt_create_date"], 'yyyy-MM-dd'),
                  'Customer' : element['vc_customer'],
                  'From' : element['Origin'],
                  'To' : element['Destination'],
                  'Boxes' : element['Boxes'],
                  'Pieces' : element['Pieces'],
                  'CFT' : element['CFT'],
                  'CBM' : element['CBM'],
                  'Actual Weight' : element['Act_Weight'],
                  'Charged Weight': element['chr_Weight'],
                  'Ewaybill No' : element['ewb_no'],
                  'Invoice No': element['invoice_no'],
                  'Value' : element['value']

          
        })
      });
    } else {
      this.excelData.push({
                  'Manifest No' : '',
                  'Booking No' : '',
                  // 'Status' : '',
                  'Order Id' : '',
                  'XPCN' : '',
                  'XPCN Date': '',
                  'Customer' : '',
                  'From' : '',
                  'To' : '',
                  'Boxes' : '',
                  'Pieces' : '',
                  'CFT' : '',
                  'CBM' : '',
                  'Actual Weight' : '',
                  'Charged Weight': '',
                  'Ewaybill No' : '',
                  'Invoice No': '',
                  'Value' : ''

        
      })
    }
    this._downloadExcelService.exportAsExcelFile(this.excelData, 'Manifest Details');
  })
}else{
  this._lclPickup_service.getManifestDetails(this.int_Manifest_id).subscribe(data => {
    if (data['Data'].length > 0) {
        data['Data'].forEach(element => {
            this.excelData.push({
              'Manifest No' : element['strManifestId'],
              'Booking No' : element['strBookingId'],
              // 'Status' : element['status'],
              'Order Id' : element['OrderId'],
              'XPCN' : element['XPCNNo'],
              'XPCN Date' : element['XPCNDate'],
              'Customer' : element['vc_customer'],
              'From' : element['Origin'],
              'To' : element['Destination'],
              'Boxes' : element['Boxes'],
              'Pieces' : element['Pieces'],
              'CFT' : element['CFT'],
              'CBM' : element['CBM'],
              'Actual Weight' : element['Act_Weight'],
              'Charged Weight': element['chr_Weight'],
              'Ewaybill No' : element['ewb_no'],
              'Invoice No': element['invoice_no'],
              'Value' : element['value']

      
    })
  });
} else {
  this.excelData.push({
              'Manifest No' : '',
              'Booking No' : '',
              // 'Status' : '',
              'Order Id' : '',
              'XPCN' : '',
              'XPCN Date': '',
              'Customer' : '',
              'From' : '',
              'To' : '',
              'Boxes' : '',
              'Pieces' : '',
              'CFT' : '',
              'CBM' : '',
              'Actual Weight' : '',
              'Charged Weight': '',
              'Ewaybill No' : '',
              'Invoice No': '',
              'Value' : ''

    
  })
}
this._downloadExcelService.exportAsExcelFile(this.excelData, 'Manifest Details');
})
}
    

}

totalBoxes: any;
totalActualWeight: any
pieces: any
value : any;
calculateTotal() {
  this.totalBoxes = 0;
  this.totalActualWeight = 0;
  this.pieces = 0;
  this.value = 0;
  for (const item of this.manifestDetails) {
    this.totalBoxes += item.Boxes;
    this.totalActualWeight += item.Act_Weight;
    this.pieces += item.Pieces
    this.value += item.value
  }
}

openLCLXPCNPreview(item) {
  this._lclPickup_service.getLCLXPCNDetailsForFinzalization(item.XPCNId).subscribe(data => {
    if (data != null && data['Data'] != null) {

      let xpcnDetails = data['Data'];
      let goodsDesc = data['Data'].GoodsDescs;
      let packageTypes = data['Data'].PackageTypes;
      let riskTypes = data['Data'].RiskTypes;
      let freightModes = data['Data'].FreightModes;
      let invoices = data['Data'].Invoices;
      let waybills = data['Data'].WayBills;

      let gst = data['Data'].CustomerGST;
      let rate = data['Data'].Rate;
    let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
        data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
        height: 'auto',
        width: '900px',
        autoFocus: false
      });
    }
  });
}
}
