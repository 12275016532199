import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PasswordDialogComponent } from './password-dialog/password-dialog.component';
import { ProfileDialogComponent } from './profile-dialog/profile-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from '../../../shared/services/alert.service';
import { SignalRService } from 'src/app/services/signal-r.service';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers:[AlertService]
})
export class UserMenuComponent implements OnInit {
  userName = '';
  roleName = '';
  sessionDetails: any;
  public userImage = '../../../../assets/img/users/default-user.jpg';
  constructor(public dialog: MatDialog,public alertService:AlertService,private signalRService: SignalRService,private loginService:LoginService) { 
    this.sessionDetails = JSON.parse(localStorage.getItem('userSession'));
    if(this.sessionDetails!=null&&this.sessionDetails!=undefined&&this.sessionDetails!='')
    {
      this.userName = this.sessionDetails['FirstName'] + ' ' + this.sessionDetails['LastName'];
      // this.sessionDetails['FirstName'] + ' ' + this.sessionDetails['LastName'];
      this.roleName = this.sessionDetails['Role'];
    }

    // this.sessionDetails['Role'];
  }
  public openUserDialog(id) {
    let dialogRef = this.dialog.open(PasswordDialogComponent, {
        data: id,
        height: 'auto',
        width: '600px',
        autoFocus:false
    });
    dialogRef.afterClosed().subscribe(data => {
    });
}
public openUserDialogs(id) {
  let dialogRef = this.dialog.open(ProfileDialogComponent, {
      data: id,
      height: 'auto',
      width: '600px',
      autoFocus:false
  });
  dialogRef.afterClosed().subscribe(data => {
  });
}
  ngOnInit() {
  }

  logoutUser() {
    this.loginService.logOut().subscribe(res => {
    localStorage.clear();
    sessionStorage.clear();
    this.alertService.createAlert("Logged out Successfully",1);
    this.signalRService.stopConnection();
    });

  }

}
