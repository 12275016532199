import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private _http: HttpClient) { }


getTabsCount(ffvId) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-tabs-count/' + ffvId);
  }

  getZones() {
    return this._http.get(environment.apiUrl + 'api/get-zones');
  }
  getZonesFfvMapping(ffvId) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-zone-mapping/' + ffvId);
  }
  updateFfvZoneMapping(data) {
    return this._http.post(environment.apiUrl + 'api/update-ffv-zone-mapping', data);
  }
  getDistrictsByZone(ZoneId) {
    return this._http.get(environment.apiUrl + 'api/get-district-list-by-zone/' + ZoneId);
  }

  getFfvsList() {
    return this._http.get(environment.apiUrl + 'api/get-ffv-list');
  }
  getFfvsListByNameFilter(name) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-list-by-name/' + name);
  }

  // billing offices services
  getFfvBillingOffice(ffvId) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-billing-office/' + ffvId);
  }
  upsertFfvBillingOffice(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-ffv-billing-office', data);
  }
  deleteFfvBillingOffice(ffvId) {
    return this._http.delete(environment.apiUrl + 'api/delete-ffv-billing-office/' + ffvId);
  }
  updateFfvBillingOfficeStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-ffv-billing-office-status', data);
  }
  getFfvBillingOfficeHistory(billingOfficeId) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-billing-office-history/' + billingOfficeId);
  }
  CheckBillingOfficeName(ffvId, DriverName) {
    return this._http.get(environment.apiUrl + `api/find-billing-officename/${ffvId}/${DriverName}`);
  }

  // dropdown data
  getStatesList() {
    return this._http.get(environment.apiUrl + 'api/get-stateslist');
  }
  GetDistrictsByStateId(data) {
    return this._http.get(environment.apiUrl + 'api/get-districtlistbystateid?StateId=' + data)
  }
  getBranchsByState(stateId) {
    return this._http.get(environment.apiUrl + 'api/get-branches-list-by-states-id/' + stateId);
  }
  getZonesByBranch(BranchId) {
    return this._http.get(environment.apiUrl + 'api/get-zones-by-branch-id/' + BranchId);
  }
  getStateCode(stateId) {
    return this._http.get(environment.apiUrl + 'api/get-state-code-by-state-id/' + stateId);
  }

  // Driver Screen services.
  getFfvDrivers(id) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-drivers/' + id);
  }
  getFfvDriversDLNo(id) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-drivers-dl-no/' + id);
  }
  getFfvDriversList(id) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-drivers-basic-details-list/' + id);
  }
  getFfvDriverHistory(id) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-driver-history/' + id);
  }
  upsertFfvDrivers(driver) {
    return this._http.post(environment.apiUrl + 'api/upsert-ffv-driver', driver);
  }
  updateFfvDriversStatus(driver) {
    return this._http.post(environment.apiUrl + 'api/update-ffv-driver-status', driver);
  }
  deleteFfvDriver(id) {
    return this._http.delete(environment.apiUrl + 'api/delete-ffv-driver/' + id);
  }
  getFfvDriverDocuments(id) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-driver-documents/' + id);
  }
  upsertFfvDriverDocuments(driver) {
    return this._http.post(environment.apiUrl + 'api/upsert-ffv-driver-document', driver);
  }
  deleteFfvDriverDocument(id) {
    return this._http.delete(environment.apiUrl + 'api/delete-ffv-driver-document/' + id);
  }
  getFfvDriverDropdowns() {
    return this._http.get(environment.apiUrl + 'api/get-ffv-drivers-dropdowns');
  }
  swapDriver(data) {
    return this._http.post(environment.apiUrl + 'api/swap-ffv-driver', data);
  }
  ValidateDuplicateData(data) {
    return this._http.post(environment.apiUrl + 'api/ffv-driver-validate-duplicate-data', data);
  }

  // Fcl rates 
  getFfvFclRateFixed(id) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-fcl-rate-fixed/' + id);
  }
  getFfvFclRatePerKM(id) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-fcl-rate-per-km/' + id);
  }
  getFfvFclRateVariableAndFixed(id) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-fcl-rate-variable-fixed/' + id);
  }
  upsertFfvFclRateFixed(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-ffv-fcl-rate-fixed', data);
  }
  upsertFfvFclRatePerKM(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-ffv-fcl-rate-per-km', data);
  }
  upsertFfvFclRateVariableAndFixed(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-ffv-fcl-rate-variable-fixed', data);
  }

  getVehicleTypes() {
    return this._http.get(environment.apiUrl + 'api/admin/get-vehicle-types');
  }
  getDistricts() {
    return this._http.get(environment.apiUrl + 'api/get-districts');
  }
  getAddendums(ffvId) {
    return this._http.get(environment.apiUrl + 'api/get-ffvcontracts/' + ffvId);
  }

  getFclRateDropdowns(ffvId, rateId) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-fcl-rate-dropdown-options?ffvId=' + ffvId + '&rateId=' + rateId);
  }

  GetDedicatedDieselPriceByDistrict(DistrictId) {
    return this._http.get(environment.apiUrl + 'api/get-dedicated-diesel-price-by-district/' + DistrictId);
  }
  GetDynamicDieselPriceLatest() {
    return this._http.get(environment.apiUrl + 'api/get-dynamic-diesel-price-latest');
  }
  GetCentralPriceByEffectivity(data) {
    return this._http.post(environment.apiUrl + 'api/get-dynamic-diesel-price-by-filter', data);
  }
  GetDedicatedPriceByEffectivity(data) {
    return this._http.post(environment.apiUrl + 'api/get-dedicated-diesel-price-by-filter', data);
  }

  DeleteFfvFclRateFixed(fclRateId) {
    return this._http.delete(environment.apiUrl + 'api/delete-ffv-Fcl-Rate-Fixed/' + fclRateId);
  }
  DeleteFfvFclRatePerKM(fclRateId) {
    return this._http.delete(environment.apiUrl + 'api/delete-ffv-Fcl-Rate-per-km/' + fclRateId);
  }
  DeleteFfvFclRateVariableAndFixed(fclRateId) {
    return this._http.delete(environment.apiUrl + 'api/delete-ffv-Fcl-Rate-Fixed-variable/' + fclRateId);
  }
  UpdateFfvFclRateFixedStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-ffv-Fcl-Rate-Fixed', data);
  }
  UpdateFfvFclRatePerKmStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-ffv-Fcl-Rate-per-km', data);
  }
  UpdateFfvFclRateVariableAndFixedStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-ffv-Fcl-Rate-Fixed-variable', data);
  }
  getFfvFclRateFixedHistory(id) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-fcl-rate-fixed-history/' + id);
  }
  getFfvFclRatePerKmHistory(id) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-fcl-rate-per-km-history/' + id);
  }
  getFfvFclRateVariableAndFixedHistory(id) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-fcl-rate-fixed-variable-history/' + id);
  }
  updateDriverBlackListedStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-driver-blacklisted-status', data);
  }
  getDriverFFvHistory(id) {
    return this._http.get(environment.apiUrl + 'api/get-driver-ffv-history/' + id);
  }
  

  getDriverBankAccountDetails(driverId){
    return this._http.get(environment.apiUrl + 'api/get-bank-account-details-of-driver?driverId=' + driverId);

  }

  swapvehicle(data){
    return this._http.post(environment.apiUrl + 'api/swap-ffv-vehicle', data);
  }
  GetTripDetailsByAffectedDate(data)
  {
    return this._http.post(environment.coreapiUrl +'api/v2/get-trip-details-by-affected_date_and_vehicleId',data)
  }
}
