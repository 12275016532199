import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApprovalsService {

  constructor(private _http: HttpClient) { }

  GetOrdersForValidateCreditNote(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-validate-credit-note', data);
  }
  
  public approveCreditDebitNote(data){
    return this._http.post(environment.apiUrl +"approve-credit-debit-note",data)
  }

  GetOrdersForApprovedCreditNote(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-approved-credit-note', data);
  }

  rejectCreditDebitNote(data){
    return this._http.get(environment.apiUrl + 'api/reject-credit-note?NoteId='+ data);
  }

  GetPendingPaidToPayOrders(data){
    return this._http.post(environment.apiUrl + 'api/get-pending_orders-for-paid-to-pay', data);
  }

  GetApprovedPaidToPayOrders(data){
    return this._http.post(environment.apiUrl + 'api/get-approved-orders-for-paid-to-pay', data);
  }
  RejectPaidToPayInvoice(data){
    return this._http.get(environment.apiUrl + 'api/reject-paid-tppay-invoice?InvoiceId='+data);
  }

  GetLCLPendingFFVDynamicInvoicePickupDelivery(data)
  {
    return this._http.post(environment.apiUrl+'api/get-all-lcl-pending-dynamic-invoice-pickup-delivery',data);
  }

  GetLCLPendingFFVDynamicInvoiceLinehaul(data)
  {
    return this._http.post(environment.apiUrl+'api/get-all-lcl-pending-dynamic-invoice-linehaul',data);
  }
  GetLCLApprovedFFVDynamicInvoice(data)
  {
    return this._http.post(environment.apiUrl+'api/get-all-lcl-approved-dynamic-invoice',data);
  }

  UpdateLCLFreightByFirstApproval(data)
  {
    return this._http.post(environment.apiUrl+'api/Update-LCL-Freight-By-FirstApproval',data);
  }

  UpdateLCLFreightBySecondApproval(data)
  {
    return this._http.post(environment.apiUrl+'api/Update-LCL-Freight-By-SecondApproval',data);
  }

  UpdateLCLFreightByFinalApproval(data)
  {
    return this._http.post(environment.apiUrl+'api/Update-LCL-Freight-By-FinalApproval',data);
  }

  GetFCLPendingFFVDynamicInvoice(data)
  {
    return this._http.post(environment.apiUrl+'api/get-all-fcl-pending-dynamic-invoice',data);
  }

  GetFCLApprovedFFVDynamicInvoice(data)
  {
    return this._http.post(environment.apiUrl+'api/get-all-fcl-approved-dynamic-invoice',data);
  }
  GetFFVInvoicePendingPayment(data){
    return this._http.post(environment.apiUrl + 'api/get-pending-request-ffv-payment', data);
  }
  GetFFVInvoiceApprovedPayment(data){
    return this._http.post(environment.apiUrl + 'api/get-approved-ffv-payments', data);
  }
  SendOTPForFFVPayment(id){
    return this._http.get(environment.apiUrl + 'api/payments/cib-otp-icici-payment?PaymentId='+id);
  }
  InitiatePaymentforVendor(id,otp){
    return this._http.get(environment.apiUrl+ 'api/payments/initiate-payment-icici-for-ffv?PaymentId='+id+'&OTP='+otp);
  }
  GetInvoicedialogdata(id){
    return this._http.get(environment.apiUrl + 'api/get-data-for-approval-invoices?PaymentId='+id);
  }
  RejectPaymentFFV(id){
    return this._http.get(environment.apiUrl + 'api/reject-ffv-payment?PaymentId='+id);
  }
  ODAPendingXPCNs(data){
    return this._http.post(environment.apiUrl + 'api/approvals/get-oda-xpcn-pending',data);
  }
  UpsertODAforCustomer(custId,distId,isSelected){
    return this._http.get(environment.apiUrl + 'api/approvals/upsert-oda-for-customer?CustomerId='+custId+'&DistrictId='+distId+"&isSelected="+isSelected);
  }
  ODAApprovedData(data){
    return this._http.post(environment.apiUrl + 'api/approvals/get-oda-xpcn-approved',data);
  }
}
