import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { LoaderService } from './loader.service';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertService } from '../shared/services/alert.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorLoaderService implements HttpInterceptor {
  loader: boolean;
  isLoaderActive: boolean;
  constructor(public _loaderService: LoaderService, public _router: Router,private _alertService:AlertService,
    private _dialog: MatDialog 
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Initialising Loader when the http request generated

    if (req.url.includes("api/get-user-daily-action") || req.url.includes("api/get-settings-capacity") ) {
      this.removeLoader();
      return next.handle(req);
    }
    else if(req.url.includes("api/v2/pickup")){
      this.removeLoader();
      return next.handle(req).pipe(
        finalize(() => this.removeLoader()),
        catchError((err) => {
          if (err == 'Unauthorized' || err.status == 401) {
              this._dialog.closeAll(); 
              localStorage.clear()
              this._router.navigate(["/login"]);
              this._alertService.createAlert("Session Expired",0)
          }
          else {
          }
          const error = (err.error != null ? err.error.message : null) || err.statusText;
          return throwError(error);
      })
      );
    } 
    else{
      this.addLoader();
      return next.handle(req).pipe(
        finalize(() => this.removeLoader()),
        catchError((err) => {
          if (err == 'Unauthorized' || err.status == 401) {
              this._dialog.closeAll(); 
              localStorage.clear()
              this._router.navigate(["/login"]);
              this._alertService.createAlert("Session Expired",0)
          }
          else {
          }
          const error = (err.error != null ? err.error.message : null) || err.statusText;
          return throwError(error);
      })
      );
    }
  }

  public removeLoader() {
    this._loaderService.hideLoader();
    this.loader = false;
    this.isLoaderActive = false;
  }

  public handleSuccess(res) {
    this.removeLoader();
  }

  public addLoader() {
    if (!this.loader) {
      if (this.isLoaderActive) {
        this._loaderService.showLoader();
        this.loader = true;
      }
    }
  }
}
