import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppSettings } from 'src/app/app.settings';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {

  isOneLetter = false;
  isOneCapitalLetter = false;
  isOneNumber = false;
  isOneSpecialCaharacter = false;
  isMinLength = false;
  isValidate = false;
  loginData = {
    AuthToken: '',
    Password: ''
  };
  public TypeString = 'password';
  public isPassword = true;
  public form: FormGroup;
  constructor(public appSettings: AppSettings, public fb: FormBuilder, public router: Router, public alertService: AlertService,
    private _loginService: LoginService,
    private Activatedroute: ActivatedRoute
    ) {
    this.form = this.fb.group({
      'password': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      'confirmPassword': [null, Validators.compose([Validators.required, Validators.minLength(6)])]
    });
  }

  ngOnInit() {
    this.loginData.AuthToken = this.Activatedroute.snapshot.queryParams['f'] || '';
  }

  public ChangetextType(isPassword) {
    if (isPassword) {
      this.TypeString = 'password';
    } else {
      this.TypeString = 'text';
    }
      }

  public onSubmit(values: Object): void {
    if (this.form.valid) {
      if(this.isOneLetter && this.isOneCapitalLetter && this.isOneNumber && this.isOneSpecialCaharacter && this.isMinLength){
        
      if (values['password'] === values['confirmPassword']) {
      this.loginData.Password = values['password'];
      this._loginService.setPassword(this.loginData).subscribe(data => {
        if (data != null) {
          if (data['Success'] || data['Message'] === 'Link expired') {
            this.router.navigate(['/login']);
          }
        }
      });
    } else {
      this.alertService.createAlert('Passwords do not match', 0);
    }
  } else {
    this.alertService.createAlert('Invalid password criteria', 0);
  }
    }
  }
  onKeyPress(e) {
    var pswd = e.value;
    //validate the length
    if (pswd.length < 8) {
      this.isMinLength = false;
    } else {
      this.isMinLength = true;
    }
    
    //validate letter
    if (pswd.match(/[A-z]/)) {
      if (pswd != '_') {
        this.isOneLetter = true;
      } else {
        this.isOneLetter = false;
      }
    } else {
      this.isOneLetter = false;
    }
    
    //validate uppercase letter
    if (pswd.match(/[A-Z]/)) {
      this.isOneCapitalLetter = true;
    } else {
      this.isOneCapitalLetter = false;
    }
    
    //validate special character
    if (pswd.match(/[!@#\$%\^&\_\+\<\>\.\,\=\:\;\'\?\(\)\[\]\\\/\|\*{}-]/)) {
      this.isOneSpecialCaharacter = true;
    } else {
      this.isOneSpecialCaharacter = false;
    }
    
    //validate number
    if (pswd.match(/\d/)) {
      this.isOneNumber = true;
    } else {
      this.isOneNumber = false;
    }
    
  }

}
