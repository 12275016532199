import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormArray,FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../services/alert.service';
import { DatePipe } from '@angular/common';
import { ApprovalsService } from 'src/app/xp-admin-web/approvals/approvals.service';

@Component({
  selector: 'app-multi-approval-dialog',
  templateUrl: './multi-approval-dialog.component.html',
  styleUrls: ['./multi-approval-dialog.component.scss']
})
export class MultiApprovalDialogComponent implements OnInit {

  //formControl: FormGroup;
  myForm: FormArray;

  constructor(public fb: FormBuilder, public dialogRef: MatDialogRef<MultiApprovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public _alertService: AlertService, private dialog: MatDialog,
    private _datePipe: DatePipe, private _apprvalService: ApprovalsService) {

       this.myForm = this.fb.array([]);
  }

  OrderData = [];
  XPTSNo: any;
  XPTSDATE: any;
  ScreenName: any;
  ngOnInit(): void {
    debugger
    this.OrderData=this.data;

  this.OrderData.forEach(item => {
    const group = this.fb.group({
      
        TripNo: [''], 
        dec_freight: [''],
        vc_remark: ['']
    });

    group.controls['TripNo'].setValue(item.TripNo);
    //group.controls['TripNo'].disable(); 
    group.controls['dec_freight'].setValue(item.dec_freight);
    group.controls['vc_remark'].setValue(null);
    this.myForm.push(group);
});

  }

  close(data) {
    this.dialogRef.close(data);
  }


//   onSave() {
// debugger
// if (this.myForm.value != null) {
//   this.myForm.controls.FormGroup.controls
//   .forEach((formData: any) => {
//   //   this._apprvalService.UpdateLCLFreightByFinalApproval(formData).subscribe(data => {
//   //     if (data['Success']) {
//   //       this._alertService.createAlert('Updated Succesfully...!', 1);
//   //       this.close();
//   //     }
//   //   }, error => {
//   //     this._alertService.createAlert('Failed', 0);
//   //   });
//   console.log(formData)
//    });
// }

//     }


onSave(FilterData) {
  let successCount = 0; 
  const totalOrders = FilterData.length; 

  if (FilterData != null) {
    FilterData.forEach((formData: any) => {
      this._apprvalService.UpdateLCLFreightByFinalApproval(formData).subscribe(data => {
        if (data['Success']) {
          successCount++; 
          if (successCount === totalOrders) {
            this._alertService.createAlert('All orders updated successfully!', 1);
            this.close(true);
          }
        }
      }, error => {
        this._alertService.createAlert('Failed', 0);
      });
    });
  }
}




}




