import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LclDeliveryService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-delivery/lcl-delivery.service';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { LclXptsDeliveryComponent } from '../lcl-xpts-delivery/lcl-xpts-delivery.component';
import { DialogEwayBillComponent } from '../dialog-eway-bill/dialog-eway-bill.component'; 
import { DialogInvoiceComponent } from '../dialog-invoice/dialog-invoice.component';
import { LclXpcnPreviewDialogComponent } from '../lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-xpts-delivery-lcl',
  templateUrl: './xpts-delivery-lcl.component.html',
  styleUrls: ['./xpts-delivery-lcl.component.scss']
})
export class XptsDeliveryLclComponent implements OnInit {
  XptsId;

  public popoverTitle = 'Confirm Delete';
  public popoverMessage = 'Are you sure you want to delete this record?';
  public cancelClicked = false;
  screenname: boolean;
  isDeleted: boolean = false;
  manifestId: number;
  manifestNo: any;
  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any,
    private _lclDelivery_service: LclDeliveryService, public dialog:MatDialog,
    private _lclpickupservice : LclPickUpService) { }

  ngOnInit(): void {
    this.XptsId = this.data.int_manifest_id || this.data.XptsId;
    //console.log(this.data);
    this.getXPTSDetails();
 

  }
  manifestDetails = [];
  getXPTSDetails() {
    this._lclDelivery_service.getxptsdialogdetails(this.XptsId).subscribe(data => {
      console.log(data)
      if (data != null && data['Success']) {
        this.manifestDetails = data['Data']['OrderAssignments'];
      }
    })
  }
  close() {
    this.dialogRef.close({ IsDeleted: this.isDeleted });
  }

  public perviewXPTS(xptsId) {
    this._lclpickupservice.getLCLXPTSDetailsDelivery(this.XptsId ).subscribe(data => {
      const dialogRef = this.dialog.open(LclXptsDeliveryComponent, {
        data: {data : data['Data'], int_xptsId : xptsId},
        height: 'auto',
        width: 'auto',
        autoFocus: false
      });
    });
  }

  openEwayBillDetails(item){
    this._lclpickupservice.getEwayBill(item.XpcnId).subscribe(data => {
    const dialogRef = this.dialog.open(DialogEwayBillComponent, {
      data:{data:data['Data']},
      height:'auto',
      width:'600px',
      autoFocus:false
    })
    dialogRef.afterClosed().subscribe(d => {
      
    })
  })
  }

  openInvoiceDetails(item){
    const dialogRef = this.dialog.open(DialogInvoiceComponent, {
      data:{item},
      height:'auto',
      width:'600px',
      autoFocus:false
    })
    dialogRef.afterClosed().subscribe(d => {
      
    })
  }

  download() {
    this.manifestId = this.data.int_manifest_id;
    this.manifestNo = this.data.ManifestCode;
    window.open(environment.apiUrl + "InvoiceView/DownloadManifestloading?int_id=" + this.manifestId + '&vc_mnaifest=' + this.manifestNo + '&vc_screen=' + "Manifest Details", "_blank");
  }

  openLCLXPCNPreview(item) {
    this._lclpickupservice.getLCLXPCNDetailsForFinzalization(item.int_XpcnId || item.XpcnId).subscribe(data => {
      if (data != null && data['Data'] != null) {

        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;

        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;

        // window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); 
        // this._router.navigate([]).then(result => {  window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); });
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }
  

}
