import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LclEwaybillPortalService {


  headers: { headers: any; };

  constructor(private _http: HttpClient) { }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  GetGenerateCEWBLog(filter) {
    return this._http.post(environment.apiUrl + 'api/get-generate-cewb-log', filter);
  }

  GetEWBByCEWB(cewbId) {
    return this._http.get(environment.apiUrl + 'api/get-ewb-by-cewb?cewbId=' + cewbId);
  }

  GetPrintEwbLog(filter) {
    return this._http.post(environment.apiUrl + 'api/get-print-ewb-log', filter);
  }

  PrintEWBLog(ewbNo) {
    return this._http.get(environment.apiUrl + 'api/print-ewb-log?ewbNo=' + ewbNo);
  }

  InsertUpdatePartBLog(ewb) {
    return this._http.post(environment.apiUrl + 'api/update-part-b-log', ewb);
  }

  GetUpdatePartBLog(filter) {
    return this._http.post(environment.apiUrl + 'api/get-update-part-b-log', filter);
  }
}
