import { HttpClient, HttpHeaders } from '@angular/common/http';
import { INT_TYPE } from '@angular/compiler/src/output/output_ast';
import { APP_ID, Injectable } from '@angular/core';
import { event } from 'jquery';
import { responseModel } from 'src/app/_models/response.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LclPickUpService {

  headers: { headers: any; };
  Filter_com: boolean = false;

  constructor(private _http: HttpClient) { }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  getlclOrdersAssignmet(filter) {
    return this._http.post(environment.apiUrl + 'api/get-all-orders-lcl-assignment', filter);
  }


  getLclPickUpRequestDimensions(pickuprequestId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-pick-up-request-dimensions?pickRequestId=' + pickuprequestId)
  }

  getlclAllocateVehicle(filter) {
    return this._http.post(environment.apiUrl + 'api/get-all-orders-lcl-allocate_vehicle', filter);
  }
  AllocateFFVWithVehicleDriver(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-vehicle-details-lcl-pickup', data);
  }

  getAllmanifest(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-manifest-details-vehicle', data);
  }

  getmenifistdailogdetails(manifestId) {
    return this._http.get(environment.coreapiUrl + 'api/v2/get-order-details-by-manifest-id?maniFestId=' + manifestId);
  }
  getOrderXpcn(keyword){
    return this._http.get(environment.apiUrl + 'api/get-all-lcl-xpcns-booking-orders?keyword=' + keyword);
  }
  // getXPCNbyorderId(orderId) {
  //   return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-for-orders?orderId=' + orderId)
  // }
  // getXPCNbyXpcnId(xpcnId) {
  //   return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-for-XpcnId?xpcnId=' + xpcnId)
  // }
  deletemanifestorder(orderId) {
    return this._http.get(environment.apiUrl + 'api/delete-order-from-manifest?int_booking_id=' + orderId);
  }
  getpickupcountfortabs() {
    return this._http.get(environment.apiUrl + 'api/get-operations-lcl-pickup-count');
  }
  getpickupcountforddtabs() {
    return this._http.get(environment.apiUrl + 'api/get-operations-lcl-direct-delievery-count');
  }
  getcancelrejectedLCLorder(data) {
    return this._http.post(environment.apiUrl + 'api/get-cancelled-or-rejected-orders-lcl', data);
  }
  getcancelrejectedLCLorderexpress(data) {
    return this._http.post(environment.apiUrl + 'api/get-cancelled-or-rejected-orders-lcl-express', data);
  }

  getLoadboardVehiclesDriversforlcl(ffvId, orderId, allocateat) {
    return this._http.get(environment.apiUrl + 'api/get-loadboard-vehicles-drivers-for-LCL?ffvId=' + ffvId + '&orderId=' + orderId + '&allocateat=' + allocateat);
  }

  getOldManifestNumberForNewOrderId(VehicleId) {
    return this._http.get(environment.apiUrl + 'api/get-ManifestNumber-For-New-OrderId?VehcileId=' + VehicleId);
  }

  getpreparexpcnorder(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-xpcn-lcl', data);
  }

  getbookingsforexpresspartb(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-bookings-for-express-aggr-update-part-B', data);
  }

  getfianlizexpcnlcl(XpcnId) {
    return this._http.get(environment.apiUrl + 'api/get-finalize-xpcn-lcl?XpcnId=' + XpcnId);
  }
  GetDemensionsReqestlcl(data) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-pick-up-request-dimensions?pickRequestId=' + data);
  }

  getBranchstats() {
    return this._http.get(environment.apiUrl + 'api/get-lcl-order-assignment-branch-stats');
  }
  getBranchstatsExpress() {
    return this._http.get(environment.apiUrl + 'api/get-lcl-order-assignment-branch-stats-express-agreegation');
  }

  getffvstats() {
    return this._http.get(environment.apiUrl + 'api/get-lcl-ffv_stats_allocate_vehicle-stats');
  }
  getffvstatsAgreegation() {
    return this._http.get(environment.apiUrl + 'api/get-lcl-ffv_stats_allocate_vehicle-stats-Agree');
  }
  // to save/finalize xpcn 
  finalizeXPCNLCL(xpcn) {
    return this._http.post(environment.apiUrl + 'api/finalize-xpcn-lcl', xpcn);
  }

  //To pickup 
  pickupLCLorder(xpcn) {
    return this._http.post(environment.apiUrl + 'api/lcl-order-pickup', xpcn);
  }

  // to get all picked lcl orders
  pickedlclorders(xpcn) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-orders-picked', xpcn);
  }
  //Not Connected
  getLclNotConnectedData(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-orders-intrasit', data);
  }

  getlclbookingforaddxpcn(filter) {
    return this._http.post(environment.apiUrl + 'api/get-all-orders-lcl-add_xpcn', filter);
  }

  getlclbookingforaddxpcnexpressAgg(filter) {
    return this._http.post(environment.apiUrl + 'api/get-all-orders-lcl-add_xpcn-express-aggr', filter);
  }

  getlclbookingfordownloadstickers(filter) {
    return this._http.post(environment.apiUrl + 'api/get-all-orders-lcl-download-sticker', filter);
  }
  getlclbookingfordownloadstickersExpress(filter) {
    return this._http.post(environment.apiUrl + 'api/get-all-orders-lcl-download-sticker-Express', filter);
  }

  getXPCNDetails(bookingId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-details-by-bookingid?bookingId=' + bookingId);
  }
getcustomerdetails(ManifestId){
  return this._http.get(environment.apiUrl + 'api/get-lcl-Customer-details-by-ManifsetId?ManifestId=' + ManifestId);
}
  getBoxDetails(bookingId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-box-details-by-bookingid?bookingId=' + bookingId);
  }

  addOrderAndXPCNLCL(bookingId) {
    return this._http.get(environment.apiUrl + 'api/insert-lcl-order-and-xpcn-details?bookingId=' + bookingId);
  }

  cancelbooking(bookingid, Reason) {
    return this._http.get(environment.coreapiUrl + 'api/v2/cancel-lcl-booking?int_booking_id=' + bookingid + '&Reason=' + Reason);
  }

  getLCLXPCNDetailsForFinzalization(int_lcl_xpcn_id) {
    return this._http.get(environment.coreapiUrl + 'api/v2/get-lcl-xpcn-details-for-preview?int_lcl_xpcn_id=' + int_lcl_xpcn_id);
  }


  getLCLXPCNDetailsForFinalization(int_lcl_xpcn_id) {
    
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-details-for-finalization?int_lcl_xpcn_id=' + int_lcl_xpcn_id);
   }


  //To rechedule booking
  insertreschedulebookig(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/reschedule-lcl-booking', data);
  }


  getPrepareTripSheetexpressandAgree(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-trip-sheet-stats-agree-express', data);
  }


  // to get finalize xpcn
  upsertLCLOrderXPCNBoxes(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-lcl-order-xpcn-boxes', data);
  }

  getLCLOrderXPCNBoxes(int_lcl_xpcn_id) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-boxes?int_lcl_xpcn_id=' + int_lcl_xpcn_id);
  }

  downloadSticker(int_lcl_xpcn_id) {
    return this._http.get(environment.apiUrl + 'InvoiceView/DownloadLCLXPCNBoxes?xpcnid=' + int_lcl_xpcn_id);
  }

  getbookingforpickupexecutive(data) {
    return this._http.post(environment.apiUrl + 'api/get-all-booking-lcl-pickup-executive', data);
  }

  getbookingsforpickupexecexpressaggr(data){
    return this._http.post(environment.apiUrl + 'api/get-all-booking-lcl-express_aggr_pickup-executive',data)
  }

  getExecutive() {
    return this._http.get<responseModel>(environment.coreapiUrl + 'api/v2/get-lcl_executive');
  }

  getexicutivestats(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-executive-branch-stats', data);
  }

  getexecutivestatsAgreeBreakBulk(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-executive-branch-stats-agree-express', data);
  }

  insertpickupexecutive(data) {
    return this._http.post(environment.apiUrl + 'api/insert-executive-lcl-booking', data);
  }
  getbookingforpreparexpltsxpmf(data) {
    return this._http.post(environment.apiUrl + 'api/get-all-booking-lcl-xplts-xpmf', data);
  }

  getlclxpltsvehicletype(id, gatewayId) {
    return this._http.get(environment.apiUrl + 'api/get-all-xplts-xpmf/' + id + '/' + gatewayId);
    // return this._http.get(environment.apiUrl + 'api/get-all-xplts-xpmf?id=' + id + '&gatewayId=' + gatewayId);
  }

  generatemanifestxpts(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/upsert-manifest-xpts', data);
  }

  closeaddxpcntab(data) {
    return this._http.get(environment.apiUrl + 'api/close-add-xpcns?int_BookingId=' + data);
  }


  getLCLBookingDetails(bookingId){
    return this._http.get(environment.apiUrl + 'api/get-booking-details-lcl?bookingId=' + bookingId);
  }

  getWarehouseDetailsByBookingId(bookingId,warehouseType){
    return this._http.get(environment.apiUrl + 'api/get-booking-warehouse-details?bookingId=' + bookingId+"&warehouseType="+warehouseType);
  }

  updateReadyForPickupLCLExpressMilkrun(bookingId,distributionCenterId){
    return this._http.get(environment.apiUrl + 'api/close-add-xpcns-express-milkrun?bookingId=' + bookingId+"&distributionCenterId="+distributionCenterId);
  }

  getlclCancellationXpcn(data) {
    return this._http.post(environment.apiUrl + 'api/get-orders-for-cancellation-xpcn-lcl', data);
  }
  getDispatchXpcnDetailsByXptsId(xptsid,gatewayId)
  {
    return this._http.get(environment.apiUrl+'api/get-lcl-dispatch-order-details-by-xptsid?xptsid='+xptsid+'&gatewayId='+gatewayId)
  }
  getCancellationxpcnbyxpcnId(xpcnId) {
    return this._http.get(environment.coreapiUrl + 'api/v2/get-cancellation-xpcn-by-xpcnid?xpcnId=' + xpcnId)
  }

  getAllCancelledXpcn(data) {
    return this._http.post(environment.apiUrl + 'api/get-all-cancelled-xpcn', data);
  }
  getAllCancelledXpcnexpress(data) {
    return this._http.post(environment.apiUrl + 'api/get-all-cancelled-xpcn-express', data);
  }
  getAllLclValidateXptsTrips(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-orders-validate-xpts', data);
  }
  getAllLclDespatchTrips(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-orders-despatch-trips', data);
  }

  updateXPTSBreakbulkMiddleMile(bookingId, departedDate) {
    return this._http.get(environment.apiUrl + 'api/update-departure-origin-breakbulk-ml?bookingId=' + bookingId + '&departedDate=' + departedDate)
  }
  getShortExcess(data) {
    return this._http.post(environment.apiUrl + 'api/get-all-xpcn-for-short-excess', data);
  }

  getTallyDetails(bookingId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-tally-details-by-bookingid?bookingId=' + bookingId);
  }

  getLCLXPTSDetails(bookingId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpts?bookingId=' + bookingId);
  }

  getLCLXPTSDetailsDelivery(xptsId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpts-delivery?xptsId=' + xptsId);
  }



  getManifestDetails(manifest_no) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-manifest-details?int_manifest_no=' + manifest_no);
  }

  getManifestDetailsDispatch(manifest_no,isSubManifest) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-manifest-details-dispatch?int_manifest_no=' + manifest_no+"&isSubManifest="+isSubManifest);
  }

  getVACReasons() {
    return this._http.get(environment.apiUrl + 'api/get-vac-reasons-details');
  }


  getXpcnboxHu(api, id) {
    return this._http.get(environment.apiUrl + api + '?int_xpcn_id=' + id);
  }

  getXpcnboxHuForDispatchBoxes(api) {
    return this._http.get(environment.apiUrl + api);
  }

  updateManualDoc(api, filter) {
    return this._http.post(environment.apiUrl + api, filter);
  }

  updateManualLoadingUnloading(api, filter) {
    return this._http.post(environment.apiUrl + api, filter);
  }
  getLCLManifestNo(id) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-manifest-by-bookingid?bookingId=' + id)
  }
  getLCLEwayBillOthers(filter) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-ewaybills-others', filter)
  }
  getEwayBillExpiryDetails(filter) {
    return this._http.post(environment.apiUrl + 'api/get-ewaybill-expiry-records', filter)
  }
  getLCLEwayBillExtensionLog(ewbNo) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-ewaybill-extension-log?ewbNo=' + ewbNo)
  }
  getEwayBillExceptionReport(filter) {
    return this._http.post(environment.apiUrl + 'api/get-ewaybill-exception-report-lcl', filter)
  }
  getXPCNDetailsforGenerateLoadingTally(bookingId, tallyNo) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-details-for-loading-tally?bookingId=' + bookingId + '&TallyNo=' + tallyNo);
  }
  getcustomertats(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-executive-customer-stats', data);
  }
 
  getOrderLcl(data) {
    return this._http.post(environment.apiUrl + 'api/lcl-get-order-for-print-xpcn' ,data);

  }

  getInvoice(xpcnId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-Invoice?xpcnId=' + xpcnId);

  }

  getEwayBill(xpcnId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-EwayBill?xpcnId=' + xpcnId);
  }
  getOrderByBooking(data) {

    return this._http.post(environment.apiUrl + 'api/get-lcl-orderid-for-customerid', data)
  }

  getXPCN() {
    debugger
    return this._http.get(environment.apiUrl + 'api/get-all-lcl-xpcns')
  }


  // to get finalize xpcn
  getFinalizeXPCN(xpcn) {

    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-details-for-finalization?int_lcl_xpcn_id=' + xpcn);
  }

  getXPTSForCEWB(filter) {
    return this._http.post(environment.apiUrl + 'api/get-xpts-for-cewb', filter);
  }
  getDispatchboxHu(api, id, tallyId, IsSubManifest) {
    
    return this._http.get(environment.apiUrl + api + '?int_xpts=' + id + '&int_dispatch_tally_id=' + tallyId + '&IsSubManifest=' + IsSubManifest);
  }


  getXPTSForCEWBDetails(xptsId) {
    return this._http.get(environment.apiUrl + 'api/get-xpts-for-cewb-details?xptsId=' + xptsId);
  }

  generateCEWB(data) {
    return this._http.post(environment.apiUrl + 'api/generate-cewb', data);
  }

  getConsigneeDetails() {
    return this._http.get(environment.apiUrl + 'api/get-consignee-for-finalize-lcl-xpcns')
  }
  getConsigneeDetailsbyId(id) {
    return this._http.get(environment.apiUrl + 'api/get-consignee-for-finalize-lcl-xpcns-by-id/' + id)
  }

  getprintxpts(data) {
    return this._http.post(environment.apiUrl + 'api/lcl-get-order-for-print-xpts', data)
  }
  CheckGatewayForDistrict(id,xpcnId) {
    return this._http.get(environment.apiUrl + 'api/get-geteway-by-consignee-district?districtId=' + id +'&xpcnId=' + xpcnId);
  }

  getprintXpcnByXptsId(data) {
    return this._http.post(environment.apiUrl + 'api/lcl-get-xpcn-for-print-by-xptsId', data)
  }

  getXPCNDetailsGateway(bookingId, GateWayId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-details-by-bookingid-gateway?bookingId=' + bookingId + '&gateway=' + GateWayId);
  }
  getBookingsCreateRoute(filter) {
    return this._http.post(environment.apiUrl + 'api/lcl-booking-create-route', filter);
  }
  getBookingManifest(manifest, manifestSeries) {
    return this._http.get(environment.apiUrl + 'api/lcl-get-order-for-print-manifest?manifest=' + manifest + '&manifestSeries=' + manifestSeries)

  }
  updatePartBTemp(manifestId) {
    return this._http.get(environment.apiUrl + 'api/update-ewb-part-b-lcl-temp?manifestId=' + manifestId);
  }
  updatePartBLCL(BookingId) {
    return this._http.get(environment.apiUrl + 'api/update-ewb-part-b-lcl?bookingId=' + BookingId);
  }
  getPrintManifest(data) {
    return this._http.post(environment.apiUrl + 'api/lcl-get-Manifest-for-print-by-manifestId', data)
  }
  getPrintManifestboxes(data) {
    return this._http.post(environment.apiUrl + 'api/lcl-get-order-for-print-manifest_by_boxes', data)
  }
  getprintloading(tally,tallySeries) {
    return this._http.get(environment.apiUrl + 'api/lcl-get-order-for-print-loading-tally?tallyNo=' + tally + '&tallySeries=' + tallySeries)

  }

  pickupXpcnIssued(data) {
    return this._http.post(environment.apiUrl + 'api/get-bb-issued-total-xpcn-lcl', data)
  }
  pickupAgreegationXpcnIssued(data) {
    return this._http.post(environment.apiUrl + 'api/get-finalized-xpcn-agreegation-lcl', data)
  }
  pickupBreakbulk(data) {
    return this._http.post(environment.apiUrl + 'api/get-finalized-xpcn-breakbulk-lcl', data)
  }

  ConfirmRoute(data) {
    return this._http.get(environment.apiUrl + 'api/confirm-route?int_BookingId=' + data);
  }

  getlclOrdersAssignmetExpress(filter) {
    return this._http.post(environment.apiUrl + 'api/get-all-orders-lcl-assignment-exp-aggregation', filter);
  }

  getlclAllocateVehExpress(filter) {
    return this._http.post(environment.apiUrl + 'api/get-all-orders-lcl-allocate_vehicle-exp-aggregation', filter);
  }
  BBEditRoute(data) {
    return this._http.post(environment.apiUrl + 'api/lcl-edit-route', data)
  }

  
 BreakbulkEditRoute(data) {

    return this._http.post(environment.apiUrl + 'api/lcl-edit-route-breakbulk', data)
  }

  GetDeliveryRoutes(bookingId) {
    return this._http.get(environment.apiUrl + 'api/lcl-delivery-gateway?bookingId=' + bookingId)
  }

  GetPickupPicked(data) {
    return this._http.post(environment.apiUrl + 'api/get-bb-picked-total-xpcn-lcl', data);
  }
  getpickedAgreegation(data) {
    return this._http.post(environment.apiUrl + 'api/get-Agreegation-picked-total-xpcn-lcl', data);
  }
  getpickedBreakbulk(data) {
    return this._http.post(environment.apiUrl + 'api/get-Breakbulk-picked-total-xpcn-lcl', data);
  }


  getPickBBDispatched(data) {
    return this._http.post(environment.apiUrl + 'api/get-bb-dispached-total-xpcn-lcl', data);
  }

  getPickupExtraScreenCount(Filterdata) {
    return this._http.post(environment.apiUrl + 'api/get-operations-lcl-pickup-Extra-Screen-count', Filterdata)
  }
  getclosedBoxes(int_booking_id, int_gateway_id) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-hu-number-by-bookingid?int_booking_id=' + int_booking_id + '&int_gateway_id=' + int_gateway_id);
  }
  getUnloadedBoxes(int_booking_id, int_gateway_id) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-hu-number-by-unloaded-bookingid?int_booking_id=' + int_booking_id + '&int_gateway_id=' + int_gateway_id);
  }
  getShortBoxes(int_booking_id, int_gateway_id) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-hu-number-by-short-bookingid?int_booking_id=' + int_booking_id + '&int_gateway_id=' + int_gateway_id);
  }

  getcustomertatsexpress(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-executive-customer-stats-agree-express', data)
  }
  getCancallationExpress(data){
    return this._http.post(environment.apiUrl + 'api/get-orders-for-cancellation-xpcn-lcl-express', data)
  }
  getHuboxesshort(int_xpcn_id) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-hu-number-by-short-Excess-boxes_xpcnId?int_xpcn_id=' + int_xpcn_id);
  }
  AllocateFFVWithVehicleDriverPickup(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-revise-vehicle-details-lcl-pickup', data);
  }
  AllocateFFVWithVehicleDriverDelivery(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-revise-vehicle-details-lcl-delivery', data);
  }
  getXpcnboxHupicked(int_xpcn_id) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-hu-number-by-xpcn-id-unloading?int_xpcn_id=' + int_xpcn_id);
  }
  getXpcnboxHutotal(int_xpcn_id) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-hu-number-by-xpcn-id?int_xpcn_id=' + int_xpcn_id);
  }
  getewaybillforbooking(BookingId){
    return this._http.get(environment.apiUrl + 'api/get-lcl-eway-by-bookigId?BookingId=' + BookingId);
  }


 getewaybillforXPCNid(xpcnid){
    return this._http.get(environment.apiUrl + 'api/get-lcl-eway-by-xpcnid?xpcnid=' + xpcnid);
  }

  getfailedewaybillforbooking(BookingId,DeliveryXPTSID,DispatchXPTSID){
    return this._http.get(environment.apiUrl + 'api/get-lcl-failed-eway-by-Id?BookingId=' + BookingId + '&deliveryXPTSId=' + DeliveryXPTSID + '&DispatchXPTSId='+ DispatchXPTSID);
  }


  confirmbookingpickedexpress(BookingId){
    return this._http.get(environment.apiUrl + 'api/update-booking-picked-confirm-lcl-express?BookingId=' + BookingId + '&isFromDeliveryApp='+ false);
  }
  updateGateway(api,BookingId, GateWayId){
    return this._http.get(environment.coreapiUrl + api + BookingId + '&GateWayId='+ GateWayId);
  }

  GetUnpickedXpts(XptsId){
    return this._http.get(environment.apiUrl + 'api/get-unpicked-bookings-by-xptsid?XptsId=' + XptsId);
  }

  updatexpts(data) {
    return this._http.post(environment.apiUrl + 'api/update-unpicked-reasons-and-finalized-xpts-lcl-express', data)
  }

  updateallpickedxpts(data) {
    return this._http.post(environment.apiUrl + 'api/update-allpicked-reasons-and-finalized-xpts-lcl-express', data)
  }

  GetCustomerDetails(ManifestId) {
    return this._http.get(environment.coreapiUrl + 'api/v2/get-customer-bookig-details-by-manifest/'+ ManifestId)
  }

  getBookingboxHu(api,id){
    return this._http.get(environment.apiUrl + api + '?int_booking_id=' + id);
  }
  getLoadedBookingboxHu(api,id){
    return this._http.get(environment.apiUrl + api + '?int_booking_id=' + id);
  }
  getLCLInvoiceNo(id){
    return this._http.get(environment.apiUrl + 'api/get-lcl-invoice-details-by-booking-id?bookingId=' + id)
  }
  getCountForTabsExpress(){
    return this._http.get(environment.coreapiUrl + 'api/v2/pickup/express/count')
  }

  updateCacheCount(name,count){
    return this._http.get(environment.coreapiUrl + 'api/v2/update/count?name=' + name + '&count=' + count )
  }
  removeUnpickedBookingFromXPTS( api){
    return this._http.get(environment.apiUrl + api)
  }
// for edit freight (lcl) manifest
updatefreightforlcl(data) {
  return this._http.post(environment.apiUrl + 'api/update-freight-for-lcl', data);
}// for edit freight (lcl) delievery
updatefreightfordelievery(data) {
  return this._http.post(environment.apiUrl + 'api/update-freight-for-lcl-delievery', data);
}
  checkxpcnfinalizestatus(ID){
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-finalise-status?xpcnId='+ ID)
  }
  filterVehicle(number){
    return this._http.get(environment.apiUrl + 'api/get-lcl-ffv-vehicle-by-vehicleNo/' + number);
  }

  upsertnewvehicle(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-assign-new-old-vehicle-details-lcl-Express', data);
  }

  getLCLXPTSDetailsExpress(xptsId){
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpts-pickup-express-aggr?xptsId=' + xptsId);
  }
  getAllXPCNDetails(bookingId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-data?bookingId=' + bookingId);
  }
  getAllXPCNDetailsXpts(xptsid,gatewayid)
  {   
    return this._http.get(environment.apiUrl + 'api/get-lcl-dispatch-order-details-by-xptsid?xptsid=' + xptsid + '&GateWayId='+ gatewayid);
  }
  Countd2c() {
    return this._http.get(environment.coreapiUrl + 'api/v2/lcl/d2c/tab-counts');
  }

  getCountForTabsAgreegation(){
    return this._http.get(environment.apiUrl + 'api/get_all_count_for_aggreegation')
  }

  getLCLXPCNForAdminControl(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-xpcn-for-admin-control',data);
  }
  getbookingbranch(data){
    return this._http.post(environment.apiUrl + 'api/get-lcl-stats-dd-ffv_allocation-stats',data);
  }
  GetAll_Express_Agree_cancelled_xpts(data){
    return this._http.post(environment.apiUrl + 'api/get-all-cancelled-lcl-exp-aggregation-xpts',data);
  }

  GetAll_Dispatch_cancelled_xpts(data){
    return this._http.post(environment.apiUrl + 'api/get-all-cancelled-lcl-dispatch-xpts',data);
  }
  canceldeleteexpressaggrxpts(XptsId){
    return this._http.get(environment.coreapiUrl + 'api/v2/delete-cancel-lcl-express-aggr-xpts?XPTSId='+XptsId)
  }

  ManifestDetailsbyStatus(id,status){
    return this._http.get(environment.apiUrl + 'api/get-manifest-details-by-status?maniFestId=' + id + '&status=' + status);
  }
  updatedateandtime(list){
    return this._http.post(environment.apiUrl + 'api/update-delievery-datetime',list);
  }

  updateDriverandNumber(data){
    return this._http.post(environment.apiUrl + 'api/change_driver_namenumber',data);
  }
  // count21 = 0;
  // count22 = 0;
  // count23 = 0;
  // count24 = 0;
  // count25 = 0;
  // count26 = 0;
  // count27 = 0;
  // count28 = 0;
  // count29 = 0;
  // count30 = 0;
  // count31 = 0;
  // count32 = 0;
  // count33 = 0;
  // public getCountExpress() {
  //   this.getCountForTabsExpress().subscribe(data => {
  //     if (data != null) {
  //       this.count21 = data['Data']['AssignPickupExecutivecount'],
  //         this.count22 = data['Data']['FinalizeXpcnCount'],
  //         this.count23 = data['Data']['CancellationXpcnCount'],
  //         this.count24 = data['Data']['DownloadStickerCount'],
  //         this.count25 = data['Data']['UpdatePartBExpress'],
  //         this.count26 = data['Data']['PrepareFinalisedXpcnCount'],
  //         this.count27 = data['Data']['FFVAllocation'],
  //         this.count28 = data['Data']['AllocateVehicle'],
  //         this.count29 = data['Data']['ProvisionalTripSheet'],
  //         this.count30 = data['Data']['PickedCount'],
  //         this.count31 = data['Data']['XPCNIssuedCount'],
  //         this.count32 = data['Data']['CancelledBookingCount'],
  //         this.count33 = data['Data']['CancelledXPCN']
  //     }
  //   });
  // }

  updateDimensionsOfBox(data){
    return this._http.post(environment.apiUrl + 'api/update-lcl-box-dimension',data);
  }

  getlclexpressbrnchstats(CustomerId){
    return this._http.get(environment.apiUrl + 'api/get-all-lcl-express-branch-stats?CustomerId=' + CustomerId);
  }

  getcustomertatsexpressaddxpcn(BranchId){
    return this._http.get(environment.apiUrl + 'api/get-all-lcl-express-customer-stats?BranchId=' + BranchId);
  }

  getpickedbookingsstatsexpress(type,fromdate,todate){
    return this._http.get(environment.apiUrl + 'api/get-all-picked-lcl-express-booking-stats?datetype=' + type + '&fromdate='+fromdate+ '&todate='+todate);
  }

  getpickedbookingsprovtripstatsexpress(type,fromdate,todate){
    return this._http.get(environment.apiUrl + 'api/get-all-lcl-express-prov-trip-customer-stats?datetype=' + type + '&fromdate='+fromdate+ '&todate='+todate);
  }

  getLCLMISDetails(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/get-lcl-mis-details',data);
  }
  // getXPCNbybookingId(BookingId) {
  //   return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-for-bookingId?BookingId=' + BookingId)
  // }

  getVehicleDetailsByVehicleNumber(vehicleNumber){
    return this._http.get(environment.apiUrl + 'api/get-vehicle-details-by-vehicle-number?vehicleNumber='+vehicleNumber);
  }

  updateMnr(data){
    return this._http.get(environment.coreapiUrl + data);
  }

  MnrHistory(data){
    return this._http.get(environment.coreapiUrl + data);
  }

  ChangeExecutiveLcl(BookingId, ExecutiveName, ExecutiveId) {
    return this._http.get(environment.coreapiUrl + 'api/v2/change/executive/' + BookingId + '/' + ExecutiveName + '/' + ExecutiveId);
  }

  upsertLCLOrderXPCNBoxesAll(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-lcl-order-xpcn-boxes-all-including-deleted', data);
  }

  deleteXPCNBoxesConsignor(boxId) {
    return this._http.get(environment.apiUrl + 'api/delete-xpcn-box-consignor?boxId=' + boxId);
  }

  getlclratesforfinalizexpcn(data){
    return this._http.post(environment.apiUrl + 'api/get-customer-lcl-rates-for-finalize-xpcn',data);
  }

  generateConsignorBoxDetails(data) {
    return this._http.post(environment.apiUrl + 'api/generate-consignor-box-details', data);
  }

  updateQuantityOfStickersUsed(data) {
    return this._http.post(environment.apiUrl + 'api/update-quantity-of-stickers', data);
  }


  getEwayBillUpdatedExpiryDetails(filter) {
    return this._http.post(environment.apiUrl + 'api/get-ewaybill-updated-lcl-records', filter)
  }
  getlclbbratesforbookorder(data){
    return this._http.post(environment.apiUrl + 'api/get-customer-lcl-bb-rates-for-booking',data);
  }

  getlclddratesforbookorder(data){
    return this._http.post(environment.apiUrl + 'api/get-customer-lcl-directdelivery-rates-for-booking',data);
  }

  unfinaliseXPCNId(xpcnId){
    return this._http.get(environment.apiUrl + 'api/unfinalise-lcl-xpcn?xpcnId='+xpcnId);
  }
  getLCLStickerLogDetails(xpcn) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-sticker-log-data?xpcn=' + xpcn);
  }




  // CORE API START
  getbookingsforpickupexecexpressCore(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/assignexecutive',data);
  }

  getstatsDatapickupexecutive(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/assignexecutive/stats',data);
  }

  AssignPickupExecutive(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/assign-executive',data);
  }

  getaddxpcndetail(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/addxpcn',data);
  }
  getXPCNDetailsCore(bookingId) {
    return this._http.get(environment.coreapiUrl + 'api/v2/get-lcl-xpcn-details-by-bookingid?bookingId=' + bookingId);
  }


  getCancellationxpcnbyxpcnIdCore(xpcnId) {
    return this._http.get(environment.coreapiUrl + 'api/v2/get-cancellation-xpcn-by-xpcnid?xpcnId=' + xpcnId)
  }

  
  fetchData(data, type) {
    switch (type) {
      case 1:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/assignexecutive', data);
      case 2:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/addxpcn', data);
      case 3:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/downloadstricker', data);
      case 4:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/update-partb', data);
      case 6:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/preparetripsheet', data);
      case 7:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/ffv-allocation', data);
      case 8:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/vehicle-allocation', data);
      case 9:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/provisonalTripsheet',data);  
      case 10:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/pickedbookings', data);  
      case 11:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/first-mile/closed-manifest', data);
      case 12:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/cancelled-bookings' , data)  
      case 13:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/cancelled-xpcns', data);  
      case 14:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/cancelled-xpts' , data)  
    }
  }
  
  fetchGraph(data, type) {
    switch (type) {
      case 1:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/assignexecutive/stats', data);
      case 2:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/addxpcn/stats', data);
      case 6:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/preparetripsheet/stats', data);
      case 7:
        return this._http.get(environment.coreapiUrl + 'api/v2/pickup/express/ffv-allocation/stats?keyword=' + data['GatewayName']);
      case 8:
        return this._http.get(environment.coreapiUrl + 'api/v2/pickup/express/vehicle-allocation/stats?keyword=' + data['CustomerName']);
      case 10:
        return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/stats',data);  
    }
  }
  

  getstatsaddxpcndetails(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/addxpcn/stats',data);
  }


  GetPrepareTripSheet(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/preparetripsheet',data);
  }

  GetstatsPrepareTripsheetdetails(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/preparetripsheet/stats',data);
  }
  
  GetBookingDetails(BookingId){
    return this._http.get(environment.coreapiUrl + 'api/v2/get-booking-details?bookingid=' + BookingId );
  }

  GetBookingXpcnDetails(BookingId){
    return this._http.get(environment.coreapiUrl + 'api/v2/get-booking-details-xpcn?bookingid=' + BookingId );
  }

  GetFFVAllocationData(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/ffv-allocation',data);
  }
  GetFFvallocationStats(length){
    return this._http.get(environment.coreapiUrl + 'api/v2/pickup/express/ffv-allocation/stats?length=' + length );
  }
  GetVehicleallocationStats(){
    return this._http.get(environment.coreapiUrl + 'api/v2/pickup/express/vehicle-allocation/stats' );
  }
  GetVehicleAllocationData(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/vehicle-allocation',data);
  }

  GetProvisonalTripSheet(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/provisonalTripsheet',data);
  }


  GetManifestOrderDetails(xptsId){
    return this._http.get(environment.coreapiUrl + 'api/v2/get-orders-details-manifest?xptsId='+ xptsId);
  }

  GetPickedBookingExpress(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/pickedbookings', data);
  }

  GetPickedManifestExpressCustomer(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/picked/stats/customer',data);
  }

  GetPickedManifestExpressGateway(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/picked/stats/gateway',data);
  }

  GetCancelledXpcnsExpress(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/cancelled-xpcns', data);
  }

  GetDownlaodStrickersExpress(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/downloadstricker', data);
  }

  GetUpdatePartBExpress(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/update-partb', data);
  }
  GetCancellationXpcn(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/cancellationxpcn' , data)
  }

  CancelledBookings(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/cancelled-bookings' , data)
  }

  CancelledXpts(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/pickup/express/cancelled-xpts' , data)
  }

  GetManifestDetails(xptsId){
    return this._http.get(environment.coreapiUrl + 'api/v2/get-manifest-details?xptsId=' + xptsId );
  }

  updateLCLBoxDetailsOnEditXPCN(data : any){
    return this._http.post(environment.coreapiUrl + 'api/v2/update-lcl-box-details' , data)
  }

  getXPTSDetailsByTripsheetNumber(xptsNumber? : string){
    return this._http.get(`${environment.coreapiUrl}api/v2/get-lcl-tripsheet-details-by-tripsheet-number?xptsNumber=${xptsNumber}`);
  }

  
  getWarehouseDetailsBooking(bookingId,warehouseType){
    return this._http.get(environment.coreapiUrl + 'api/v2/get-booking-warehouse-details?bookingId=' + bookingId+"&warehouseType="+warehouseType);
  }

  readytoPickup(data) {
    return this._http.get(environment.coreapiUrl + 'api/v2/close-add-xpcns?bookingId=' + data);
  }

  rescheduleBooking(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/reschedule-lcl-booking', data);
  }


  cancelBooking(bookingid, Reason) {
    return this._http.get(environment.coreapiUrl + 'api/v2/cancel-lcl-booking?int_booking_id=' + bookingid + '&Reason=' + Reason);
  }

  getUsers() {
    return this._http.get<responseModel>(environment.coreapiUrl + 'api/v2/get-lcl_executive');
  }

  GetVehicleType(id, gatewayId) {
    return this._http.get(environment.coreapiUrl + 'api/v2/get-all-xplts-xpmf/' + id + '/' + gatewayId);
  }
  generateXpts(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/upsert-manifest-xpts', data);
  }

  getCustomerList() {
    return this._http.get(environment.coreapiUrl + 'api/v2/get-customers-capacity');
  }

  getBoxes(LookupId, LookupType) {
    switch (LookupType) {
      case 'Booking':
        return this._http.get(environment.apiUrl + 'api/get-lcl-box-details-by-bookingid?bookingId=' + LookupId);
      case "xpts":
        return this._http.get(environment.apiUrl + 'api/get-lcl-express-boxes-details-by-xpts-id?xptsId=' + LookupId);
      default:
        throw new Error('Invalid LookupType');
    }
  }

  getFFVDD() {
    return this._http.get<responseModel>(environment.coreapiUrl + 'api/v2/get-ffv-dd');
  }

  AllocateFFVWithVehicleDrivercore(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/upsert-vehicle-details-lcl-pickup', data);
  }

  getLoadboardVehiclesDriversforlclcore(ffvId, orderId, allocateat) {
    return this._http.get(environment.apiUrl + 'api/get-loadboard-vehicles-drivers-for-LCL?ffvId=' + ffvId + '&orderId=' + orderId + '&allocateat=' + allocateat);
  }
  

  //END CORE API
  getDispatchXpcnDetailsByXptsIdForSubTally(xptsid)
  {
    return this._http.get(environment.apiUrl+'api/get-lcl-dispatch-order-details-by-xptsid-for-sub-tally?xptsid='+xptsid)
  }

  getData(api){
    return this._http.get(api)
  }
}


