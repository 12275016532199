import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppSettings } from 'src/app/app.settings';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {

  private messageSource = new BehaviorSubject<any>(null);
  currentMessage = this.messageSource.asObservable();

  private messageSourceGlobal = new BehaviorSubject<any>(null);
  currentMessageFilter = this.messageSourceGlobal.asObservable();


  constructor(private _http: HttpClient,
    private _appSettings: AppSettings) { }


    //To get summaryfor executivesummary
  GetSummaryXPFinance() {
    return this._http.get(environment.apiUrl + 'api/get-summary-status-xp-finance');
  }

  //To get the non validated orders of an associate
  GetAllAssociateOrdersCount() {
    return this._http.get<any>(environment.apiUrl + 'api/get-associate-orders-count');
  }
  //To delete associate invoice
  DeleteAssociateInvoice(AssociateInvoiceId) {
    return this._http.get<any>(environment.apiUrl + 'api/delete-invoice-associate?AssociateInvoiceId='+AssociateInvoiceId);
  }

  GetNotValidatedStatusFFv(TripId) {
    return this._http.get<any>(environment.apiUrl + 'api/get-trip-from-validated-to-non-validated?TripId='+TripId);
  }

  GetNotValidatedStatus(OrderId) {
    return this._http.get<any>(environment.apiUrl + 'api/update-validation-status?OrderId='+OrderId);
  }
  //To get the non validated orders of an associate
  GetNonValidatedOrdersAssociate(filterForm) {
    return this._http.post<any>(environment.apiUrl + 'api/get-non-validated-order-associate', filterForm);
  }
  //To get the validated orders of an associate
  GetValidatedOrdersAssociate(filterForm) {
    return this._http.post<any>(environment.apiUrl + 'api/get-validated-order-associate', filterForm);
  }
  //To get the validated orders of an associate
  GetAssociateInvoiceSerialNumber(AssociateId: number) {
    return this._http.get<any>(environment.apiUrl + 'api/get-associate-invoice-serial-number?AssociateId=' + AssociateId);
  }

  //To get the billing orders
  GetBilledOrdersOfAssociate(filterForm) {
    return this._http.post<any>(environment.apiUrl + 'api/get-billed-order-associate',filterForm);
  }
  //To get the unbilled orders
  GetUnbilledOrdersOfAssociate(filterForm) {
    return this._http.post<any>(environment.apiUrl + 'api/get-unbilled-order-associate',filterForm);
  }

  //To get the billing orders of invoice
  GetBilledOrdersOfAssociateInvoice(AssociateInvoiceId) {
    return this._http.get<any>(environment.apiUrl + 'api/get-billed-orders-of-invoice?AssociateInvoiceId='+AssociateInvoiceId);
  }
  //To get the unbilled orders of associate
  GetUnbilledOrdersByAssociate(data) {
    return this._http.post<any>(environment.apiUrl + 'api/get-unbilled-orders-associate',data);
  }
  
  //To get the unbilled orders of invoice
  GetBilledUnbilledAmount(filterData) {
    return this._http.post(environment.apiUrl + 'api/get-billed-unbilled-associate',filterData);
  }
  
  //To get dasboard charts
  GetBranchesListOfAssociate(filterData) {
    return this._http.post(environment.apiUrl + 'api/get-associate-dashboard-charts',filterData);
  }
  
  //To get dasboard Summary
  GetAssociateDashboardSummary(filterData) {
    return this._http.post(environment.apiUrl + 'api/get-associate-dashboard-summary',filterData);
  }
  
  deleteOnAccount(onaccountid) {
    return this._http.get(environment.apiUrl + 'api/delete-on-account?onaccountid=' + onaccountid);
   }
  GetSavedObject() {
    return this._appSettings.AssociateOrdersSelectedForInvoice;
  }

  GetInvoicePreview(data) {
    return this._http.post(environment.apiUrl + 'generate-invoice-pdf', data);
  }
  FinilizeInvoice(data) {
    return this._http.post(environment.apiUrl + 'api/finalize-associate-invoice', data);
  }
  downloadFile(url: string): any {
    return this._http.get(url, { responseType: 'blob' })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  sendInvoice(AssociateInvoiceId){
    return this._http.get(environment.apiUrl + 'api/send-associate-invoice-mail?AssociateInvoiceId='+AssociateInvoiceId);
  }

  downloadPDF(url) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    console.log('ff')
    return this._http.get(url, { headers: headers, responseType: 'blob' });
  }

  //To get the non validated trips of an ffv
  GetNonValidatedTripsFFV(filterForm) {
    return this._http.post<any>(environment.apiUrl + 'api/get-not-validated-trips-ffv', filterForm);
  }
  GetNonValidatedTripsFFVNew(filterForm) {
    return this._http.post<any>(environment.apiUrl + 'api/get-not-validated-trips-ffv-new', filterForm);
  }
  SpiltBillIntoParts(filterForm) {
    return this._http.post<any>(environment.apiUrl + 'api/spilt-bill-into-parts', filterForm);
  }

  //To get ffv's list
  GetFFVList() {
    var respdata = this._http.get<any>(environment.apiUrl + 'api/get-FFVBillingFFVsList');
    return respdata;
  }

  getFFVById(id) {
    return this._http.get(environment.apiUrl + 'api/get-ffv-details-finance/' + id);
  }

  //for validating trips 
  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  changeMessageFilter(message: string) {
    this.messageSourceGlobal.next(message)
  }

   //To get the validated trips of an ffv
  GetValidatedTripsFFV(filterForm) {
    return this._http.post<any>(environment.apiUrl + 'api/get-validated-trips-ffv', filterForm);
  }
  GetValidatedTripsFFVNEW(filterForm) {
    return this._http.post<any>(environment.apiUrl + 'api/get-validated-trips-ffv-new', filterForm);
  }
  //To change status of trips from not validated to validate 
  public UpdateTripByID(grouplists){
    var respdata =  this._http.post(environment.apiUrl + 'api/validate_save_trip_new',grouplists);
    return respdata;
  }

  //To get charges of a trip 
  public GetTripCharges(filterForm){
    console.log(filterForm,'check');
    return this._http.post(environment.apiUrl + 'api/get-trip-charges/',filterForm);

  }
  public GetTripChargesNEW(filterForm){
    return this._http.post(environment.apiUrl + 'api/get-trip-charges-new/',filterForm);

  }
  //update trip charges
  public UpdateTripCharges(filterForm){
    return this._http.post<any>(environment.apiUrl + 'api/upsert-trip-charges', filterForm);
  }
  public UpdateTripChargesNew(filterForm){
    return this._http.post<any>(environment.apiUrl + 'api/update-trip-charges', filterForm);
  }

  //Get validated trips for generate invoice 
  GetValidatedTripsGenerateInvoice(filterForm) {
    return this._http.post<any>(environment.apiUrl + 'api/get-validated-trips-for-generateinvoice', filterForm);
  }
  GetValidatedTripsGenerateInvoiceNew(filterForm) {
    return this._http.post<any>(environment.apiUrl + 'api/get-validated-trips-for-generateinvoice-new', filterForm);
  }
  //Get validated trips for generate f&v invoice 
  GetValidatedTripsGenerateFVInvoice(filterForm) {
    return this._http.post<any>(environment.apiUrl + 'api/get-validated-trips-for-generatefvinvoice', filterForm);
  }

  GetFFVInvoiceSerialNumber(FFVId: number) {
    return this._http.get<any>(environment.apiUrl + 'api/get-ffv-invoice-serial-number?FFVId=' + FFVId);
  }

  GetUnbilledTripsFFV(filterForm) {
    return this._http.post<any>(environment.apiUrl + 'api/get-unbilled-trips-ffv', filterForm);
  }

  GetBilledInvoicesFFV(filterForm) {
    return this._http.post<any>(environment.apiUrl + 'api/get-billed-invoices-ffv', filterForm);
  }


  //Generate FFV Invoice 
  GetFFVInvoicePreview(data) {
   // console.log(data,'data');
    return this._http.post(environment.apiUrl + 'generate-ffv-invoice-pdf', data);
  }
   //Generate FFV Invoice 
   GetFFVInvoicePreviewNew(data) {
    // console.log(data,'data');
     return this._http.post(environment.apiUrl + 'generate-ffv-invoice-pdf-new', data);
   }
  FinilizeFFVInvoice(data) {
    return this._http.post(environment.apiUrl + 'api/finalize-ffv-invoice', data);
  }
  FinilizeFFVInvoiceNew(data) {
    return this._http.post(environment.apiUrl + 'api/finalize-ffv-invoice-new', data);
  }
  
  sendFFVInvoice(FFVInvoiceId){
    return this._http.get(environment.apiUrl + 'api/send-ffv-invoice-mail?FFVInvoiceId='+FFVInvoiceId);
  }

  //Generate F&V Invoice
  getMarkAttendance(data) {
    console.log(data,'check filter');
    return this._http.post(environment.apiUrl + 'api/get-mark-attendance-for-billing' ,data);
  }

   //Generate FFV Invoice 
   GetFVInvoicePreview(data) {
    console.log(data,'data');
    return this._http.post(environment.apiUrl + 'generate-fv-invoice-pdf', data);
  }
  FinilizeFVInvoice(data) {
    return this._http.post(environment.apiUrl + 'api/finalize-fv-invoice', data);
  }
  
  DeleteFFVInvoice(FFVInvoiceId) {
    return this._http.get<any>(environment.apiUrl + 'api/delete-invoice-ffv?FFVInvoiceId='+FFVInvoiceId);
  }

  ApproveFFVInvoice(reqdata){
    return this._http.post(environment.apiUrl + 'api/accept_invoice_by_ffv',reqdata);
  }

  GetInvoiceExceptions(){
    return this._http.get<any>(environment.apiUrl + 'api/get-invoice-case-raise-exceptions');
  }

  
  public GetFFVInvoiceCaseDetails(caseid) {
    //console.log("case data is", caseid);
    return this._http.get(environment.apiUrl + 'api/get-invoice-case-info-ffv?CaseID=' + caseid);
  }

  
   GetFFVDashBoardCharts(filterData) {
    return this._http.post(environment.apiUrl + 'api/get-ffv-billing-dashboard-charts',filterData);
  }
 

   GetBilledUnbilledAmountFFV(filterData) {
    return this._http.post(environment.apiUrl + 'api/get-billed-unbilled-amount-ffv',filterData);
  }
  
  GetFFVDashboardSummary(filterData) {
    return this._http.post(environment.apiUrl + 'api/get-ffv-dashboard-summary',filterData);
  }

  public GetUnBilledOrdedsByFFV(filterData) {
    return this._http.post(environment.apiUrl + 'api/get-unbilled-orders-ffv',filterData);
  }

  GetFFVInvoiceOrders(FFVInvoiceId) {
    return this._http.get(environment.apiUrl + 'api/get-billed-orders-of-ffv-invoice?FFVInvoiceId=' + FFVInvoiceId);
  }

  public generateCreditDebitNoteFFV(data){
    return this._http.post(environment.apiUrl +"api/generate-credit-debit-note-ffv",data)
  }

  UpdateFFVInvoiceCase(data){
    return this._http.post(environment.apiUrl +"api/upsert-ffv-invoice-case",data)
  }

  GetFFVOrdersCount() {
    return this._http.get(environment.apiUrl + 'api/get-ffv-billing-orders-count');
  }

  public getHaltingHoursBreakupFFV(OrderId: number) {
    return this._http.get(environment.apiUrl + "api/get-halting-charges-breakup-order-ffv?OrderId=" + OrderId);
  }

  //FFV PAYABLES
  GetRevenueSaved(data) {
    return this._http.post(environment.apiUrl + "api/get-ffv-revenue-saved", data);
  }

  GetFFVDueInvoices(data) {
    return this._http.post(environment.apiUrl + "api/get-ffv-dues-payable", data);
  }
  GetFFVOverDueInvoices(data) {
    return this._http.post(environment.apiUrl + "api/get-ffv-over-dues-payable", data);
  }

  GetFFVOverDueInvoicesXpFinance(data) {
    return this._http.post(environment.apiUrl + "api/get-ffv-over-dues-payable-xp-finance", data);
  }


  GetFFVPaidInvoices(data) {
    return this._http.post(environment.apiUrl + "api/get-ffv-paid-invoices", data);
  }
  GetFFVPartlyPaidInfo(data) {
    return this._http.post(environment.apiUrl + "api/get-ffv-partly-paid-invoices", data);
  }
  

  GetFFVOverDueInvoicesAgeingAnalysis(data) {
    return this._http.post(environment.apiUrl + "api/get-ffv-over-dues-ageing-analysis", data);
  }

  GetUpdateAmountFFV(InoviceId) {
    return this._http.get(environment.apiUrl + "api/get-ffv-update-payment?InoviceId=" + InoviceId);
  }

  UpsertUpdatePaymentFFV(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-invoice-update-payment-ffv', data);
  }

  GetUpdateAmountPayablesFFV(InoviceId) {
    return this._http.post(environment.apiUrl + 'api/get-ffv-payment-payable', InoviceId);
  }

  UpsertPaymentDetailsFFV(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-invoice-payment-details-ffv', data);
  }

  GetpaymentAdviseFFV(FFVId){
    return this._http.get(
      environment.apiUrl + "api/get-ffv-payment-advise?FFVId=" + FFVId); 
  }

  GetFFVCashDiscount(data) {
    return this._http.post(environment.apiUrl + "api/get-ffv-cash-discount", data);
  }

  GetFFVDelayPenalty(data) {
    return this._http.post(environment.apiUrl + "api/get-ffv-delay-penalty", data);
  }

  GetFFVCreditAmount(data) {
    return this._http.post(environment.apiUrl + "api/get-ffv-credit-amount", data);
  }

  //PAYABLES GRAPHS
  GetGraphs(data) {
    return this._http.post(environment.apiUrl + "api/get-ageing-graphs-ffv",data);
  }
  GetGraphsDues(data) {
    return this._http.post(environment.apiUrl + "api/get-dues-graphs-ffv",data);
  }
  GetGraphsRs(data) {
    return this._http.post(environment.apiUrl + "api/get-revenue-saved-graphs-ffv",data);
  }
  GetGraphsPaid(data) {
    return this._http.post(environment.apiUrl + "api/get-paid-graphs-ffv",data);
  }

  GetBarGraphFFVPayables(data){
    return this._http.post(environment.apiUrl + 'api/get-bar-graph-ffv-payables', data);
  }

  UpdatePaymentInfoCustomerReceivables(data){
    return this._http.post(environment.apiUrl+"api/update-payment-receivables-customer",data);
  }
  Gettdsreceivables(data){
    //debugger;
    return this._http.post<any>(environment.apiUrl + 'api/get-tds-receivables', data);
  }

  GettdsPayables(data){
    //debugger;
    return this._http.post<any>(environment.apiUrl + 'api/get-tds-payables', data);
  }


  GettdsPayablesMonthlyData(data){
    return this._http.post(environment.apiUrl + 'api/get-tds-payables-monthly-value', data);
  }

  GettdsPayablesQuaterlyData(data){
    return this._http.post(environment.apiUrl + 'api/get-tds-payables-quaterly-value', data);
  }

  GettdsRecQuaterlyData(data){
    return this._http.post(environment.apiUrl + 'api/get-tds-rec-quaterly-value', data);
  }

  GettdsReceviablesMonthlyData(data){
    return this._http.post(environment.apiUrl + 'api/get-tds-receviables-monthly-value', data);
  }


  Gettdsreceivablesviewamount(data){
    return this._http.post<any>(environment.apiUrl + 'api/get-tds-receivables-view-amount', data);
  }

  UpdatePaymentTds(data){
    return this._http.post(environment.apiUrl+"api/upsert-tds-update-payment",data);
  }
  GetCustomersForReceviables(data){
    return this._http.post(environment.apiUrl+"api/get-tds-customers-upload",data);
  }

GetCustomersForPayables(data){
    return this._http.post(environment.apiUrl+"api/get-tds-deductee-upload",data);
  }
  UploadTdsCertificate(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-tds-upload-certificate', data);
   }
   UploadTdsCertificatePayables(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-tds-upload-certificate-payable', data);
   }
   GetUnderSectionForPayables(data){
    return this._http.post(environment.apiUrl+"api/get-tds-payables-graph-us",data);
  }

   //GST WORKING
   GetGSTWorkingInvoices(data) {
    return this._http.post(environment.apiUrl + "api/get-gst-working-invoices", data);
  }

  GetBarGraphGSTWorking(data){
    return this._http.post(environment.apiUrl + 'api/get-ffv-associate-gst-amount-bar-graph', data);
  }

  GetLiabiltyBarGraphGSTWorking(data){
    return this._http.post(environment.apiUrl + 'api/get-ffv-associate-gst-liablity-bar-graph', data);
  }

  GetPiesGSTWorking(data) {
    return this._http.post(environment.apiUrl + "api/get-ffv-associate-gst-working-pie-chart",data);
  }
  
  GetGSTWorkingUpdateLiabilityReport(data) {
    return this._http.post(environment.apiUrl + "api/get-gst-working-update-liability-report", data);
  }

  UpdatePaymentUpdateLiabilityReport(data){
    return this._http.post(environment.apiUrl+"api/upsert-ffv-associate-gst-update-payment",data);
  }

  //Gst working
  GetGSTWorkingGstReport(data) {
    return this._http.post(environment.apiUrl + "api/get-gst-working-gst-report", data);
  }

  GetGSTWorkingGstGraphs(data) {
    return this._http.post(environment.apiUrl + "api/get-xp-gst-working-graphs", data);
  }

  UpdatePaymentXPGST(data){
    return this._http.post(environment.apiUrl+"api/upsert-xp-gst-update-payment",data);
  }
  UpdateOnAccountDetails(data){
    return this._http.post(environment.apiUrl+"api/update-onaccount-amount",data);
  }
  CheckRefNumber(data){
    return this._http.get(environment.apiUrl+"api/check-ref-number?model="+data);
  }
  GetOnAccountOnCustomer(data){
    return this._http.get(environment.apiUrl+"api/get-onaccount-based-on-customer?model="+data);
  }
  UploadGSTDocumentsXPGST(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-xp-gst-documents-xp-gst', data);
   }

   ShowReportInvoices(data) {
    return this._http.post(environment.apiUrl + "api/get-gst-working-show-report-invoices", data);
  }

  ShowDownloadCSVInvoices(data) {
    return this._http.post(environment.apiUrl + "api/get-gst-working-download-csv-invoices", data);
  }

  ShowDownloadCSVInputOthers(data) {
    return this._http.post(environment.apiUrl + "api/get-gst-working-download-sample-input-others", data);
  }
  GetAdjustedInvoicesOnAccount(id) {
    return this._http.get(environment.apiUrl + 'api/get-adjusted-invoices-onaccount/' + id);
  }
  getSpiltBill(orderid) {
    return this._http.get(environment.apiUrl + 'api/get-spilt-bill-records/' + orderid);
  }
  updateSpiltBill(data) {
    return this._http.post(environment.apiUrl + 'api/update-customer-spilt-bill' ,data);
  }
  GenerateSpiltBill(orderid) {
    return this._http.get(environment.apiUrl + 'api/generate-spilt-bill/' + orderid);
  }
  GetSpiltBillingDetailsBasedOnSpiltId(SpiltId){
    return this._http.get(environment.apiUrl + 'api/get-spilt-bill-records-by-spilt-id/' + SpiltId);
  }
  GetIntransitTripsFFV(data){
    return this._http.post(environment.apiUrl + 'api/get-intransit-ffv-trips', data);
  }
  GetIntransitTripsFFVNew(data){
    return this._http.post(environment.apiUrl + 'api/get-intransit-trips-ffv-new', data);
  }
  GetFFVLCLTotalOrders(data){
    return this._http.post(environment.apiUrl + 'api/get-ffv-lcl-total-orders', data);
  }
  ManualFFVInvoicePreview(data) {
     return this._http.post(environment.apiUrl + 'generate-ffv-manual-invoice-pdf', data);
   }
   FinalizeManualFFVInvoice(data) {
     return this._http.post(environment.apiUrl + 'api/finalize-ffv-manual-invoice', data);
   }
   getBookingIdByManifestId(manifestId)
   {
    return this._http.get(environment.apiUrl+'api/get-bookingid-by-manifestId?manifestId='+manifestId)
   }
   getOrderIdByTripId(manifestId)
   {
    return this._http.get(environment.apiUrl+'api/get-orderId-by-tripId?manifestId='+manifestId)
   }
   getFFVAllTripSheetTypes()
   {
    return this._http.get(environment.apiUrl+'api/get-ffv-all-tripsheet-types');
   }
   SendEmailwithCSVPDF(data){
    return this._http.post(environment.apiUrl + 'api/send-invoiceannexure-email', data);
   }
   GenerateRequestforBankPayment(data){
    return this._http.post(environment.apiUrl + 'api/request-invoice-payment-ffv', data);
   }
   ApproveFFVInvoices(data){
    return this._http.post(environment.apiUrl + 'api/approve-ffv-invoices', { SelectedItems: data });
   }

   GetFVInvoiceByFFVId(FFVId)
   {
    return this._http.get(environment.apiUrl + 'api/get-FV-Invoice-By-FFVID?FFVId='+FFVId);
   }
}
