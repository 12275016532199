import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FfvVehicleService {

  constructor(private _http: HttpClient) { }

  // to check Vehicle No
CheckVehicleNo(FfvId, VehicleNo) {
  return this._http.get(environment.apiUrl + 'api/check-vehicle-no/' + FfvId + '/' + VehicleNo);
}

   // to get the list of VehicleOptions
   getVehicleOptions() {
    return this._http.get(environment.apiUrl + 'api/get-vehicle-options');
  }

  // to get the list of vehicles
 getFFVVehicles(FfvId) {
  debugger
  return this._http.get(environment.apiUrl + 'api/get-ffv-vehicles/' + FfvId);
}

     // to upsert contract
 upsertFFVVehicle(data) {
  return this._http.post(environment.apiUrl + 'api/upsert-ffv-vehicle', data);
 }

  // to upload vehicle doc
  uploadVehicleDoc(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-ffv-vehicle-doc', data);
  }

   // to delete vehicle doc
 deleteVehicleDoc(data) {
  return this._http.post(environment.apiUrl + 'api/delete-ffv-vehicle-doc', data);
}

  // to update vehicle status
  updateVehicleStatus(data) {
    return this._http.post(environment.apiUrl + 'api/update-ffv-vehicle-status', data);
  }

   // to delete vehicle
 deleteFfvVehicle(data) {
  return this._http.post(environment.apiUrl + 'api/delete-ffv-vehicle', data);
}

// to get the list of vehicles history
getFFVVehiclesHistory(FfvVehicleId) {
  return this._http.get(environment.apiUrl + 'api/get-ffvvehicles-history/' + FfvVehicleId);
}

getFFVVehiclesHistoryByName(ffvvehicle) {
  return this._http.get(environment.apiUrl + 'api/get-ffvvehiclename-history/' + ffvvehicle);
}

// to get the list of preferred customers
getPreferredCustomers(ffvVehicleId) {
  return this._http.get(environment.apiUrl + 'api/get-preferred-customers?ffvVehicleId=' + ffvVehicleId);
}

// to save preferred customers
savePreferredCustomers(data) {
return this._http.post(environment.apiUrl + 'api/save-preferred-customers', data);
}
getFFVVehicleslist(FfvId) {
  debugger
  return this._http.get(environment.apiUrl + 'api/get-ffv-vehicles-list/' + FfvId);
}
}
